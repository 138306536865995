import PageTitle from '../../../components/pageTitle/PageTitle';
import AddUserForm from './AddUserForm';
import i18n from '../../../app/i18n';
import './AddUserPage.scss';

const AddUserPage = () => {
  return (
    <div className='add-user-page'>
      <PageTitle>{i18n.t('addUser.addNewUser')}</PageTitle>
      <div className='add-user-form'>
        <AddUserForm />
      </div>
    </div>
  );
};

export default AddUserPage;
