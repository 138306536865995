import { useState } from 'react';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import i18n from '../../../app/i18n';
import CreateClientLabelForm from './CreateClientLabelForm';
import './addClientLabelModal.scss';

const AddClientLabelModal = ({ onSuccessHandler }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const onCancelHandler = () => {
    setModalOpen(false);
  };

  const afterCreate = () => {
    setModalOpen(false);
    onSuccessHandler();
  };

  function ModalHtml() {
    return (
      <Modal
        className='delete-user-model'
        centered={true}
        isOpen={modalOpen}
        toggle={onCancelHandler}
      >
        <ModalHeader charcode='Y' toggle={() => setModalOpen(false)}>
          <h6>Sukurti žymą</h6>
        </ModalHeader>
        <ModalBody>
          <CreateClientLabelForm onCancelHandler={onCancelHandler} onSuccessHandler={afterCreate} />
        </ModalBody>
      </Modal>
    );
  }
  return (
    <>
      {ModalHtml()}
      <button onClick={() => setModalOpen(true)} className='client-create-btn'>
        Sukurti žymą
      </button>
    </>
  );
};

export default AddClientLabelModal;
