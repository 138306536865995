import { useState } from 'react';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import i18n from '../../../app/i18n';
import './DeleteUserModal.scss';

const DeleteUserModal = ({ rowId, onSuccess, title, textContent }) => {
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);

    const onClickHandler = () => {
        onSuccess(rowId);
        setDeleteModalOpen(false);
    };

    const onCancelHandler = () => {
        setDeleteModalOpen(false);
    };

    function deteleModal() {
        return (
            <Modal className='delete-user-model' centered={true} isOpen={deleteModalOpen} toggle={onCancelHandler}>
                <ModalHeader charcode='Y' toggle={() => setDeleteModalOpen(false)}>
                    {title}
                </ModalHeader>
                <ModalBody>
                    <h6>{textContent}</h6>
                </ModalBody>
                <ModalFooter className='d-flex justify-content-between'>
                    <button onClick={onClickHandler} className='confirm-user-deletion'>
                        {i18n.t('deleteUserPopup.deleteUser')}
                    </button>
                    <button className='cancel-user-deletion' onClick={onCancelHandler}>
                        {i18n.t('deleteUserPopup.abortDeletion')}
                    </button>
                </ModalFooter>
            </Modal>
        );
    }

    return (
        <>
            {deteleModal()}
            <button className='delete-icon-color' onClick={() => setDeleteModalOpen(true)}>
                <FontAwesomeIcon icon={faTrash} />
            </button>
        </>
    );
};

export default DeleteUserModal;
