import React, { useState } from 'react';
import { Form, Field } from 'react-final-form';
import { InputFieldWithIcon } from '../../../components/form';
import i18n from '../../../app/i18n';
import cms from '../../../app/api/cms';
import { Alert } from 'reactstrap';
import { SketchPicker } from 'react-color';

const CreateClientLabelForm = ({ onCancelHandler, onSuccessHandler }) => {
  const [formFieldValues] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [colorField, setColorField] = useState('');

  const validate = (values) => {
    const errors = {};

    if (!values.title) {
      errors.title = i18n.t('formErrors.requiredField');
    }
    if (!values.color_hex) {
      errors.color_hex = i18n.t('formErrors.requiredField');
    }

    return errors;
  };

  const handleColorChange = (color, form, values) => {
    setColorField(color.hex);
    form.reset({
      ...values,
      color_hex: color.hex,
    });
  };

  const onSubmit = async (values) => {
    let formData = new FormData();
    setErrorMessage('');

    formData.append('title', values.title);
    formData.append('color_hex', values.color_hex);

    let isErrors = false;
    let formErrors = {};

    await cms
      .CreateClientLabel(formData)
      .then(function (response) {
        if (response.data.data !== undefined) {
          onSuccessHandler();
        }
      })
      .catch(function (error) {
        if (error.response.data.error !== undefined) {
          setErrorMessage(i18n.t(`formErrors.${error.response.data.error}`));
        }
        if (error.response.data !== undefined) {
          for (const [key, value] of Object.entries(error.response.data)) {
            formErrors[key] = i18n.t(`formError.${value}`);
          }
          isErrors = true;
        }
      });
    if (isErrors) {
      return formErrors;
    }
  };

  const formFields = [
    {
      field: (
        <Field name='title'>
          {({ input, meta }) => (
            <InputFieldWithIcon
              placeholder='Pavadinimas'
              input={input}
              meta={meta}
              label='Pavadinimas'
            ></InputFieldWithIcon>
          )}
        </Field>
      ),
    },
    {
      field: (
        <Field name='color_hex'>
          {({ input, meta }) => (
            <InputFieldWithIcon
              placeholder='Spalva'
              input={input}
              meta={meta}
              label='Spalva'
            ></InputFieldWithIcon>
          )}
        </Field>
      ),
    },
  ];

  return (
    <>
      {errorMessage && errorMessage !== '' ? <Alert color='danger'>{errorMessage}</Alert> : ''}
      <Form
        onSubmit={onSubmit}
        initialValues={formFieldValues}
        validate={validate}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form onSubmit={handleSubmit} className='user-profile-update-form' noValidate>
            <div className='row'>
              <div className='col-lg-6 col-md-12'>
                <div className='row'>
                  {formFields.map((item, key) => (
                    <div className='col-lg-12' key={`${key}-fields`}>
                      {item.field}
                    </div>
                  ))}
                </div>
              </div>

              <div className='col-lg-6 mb-4'>
                <SketchPicker
                  color={colorField}
                  onChangeComplete={(color) => handleColorChange(color, form, values)}
                />
              </div>
            </div>
            <div className='d-flex justify-content-between'>
              <button className='add-client-btn cancel-user-deletion' type='submit'>
                {i18n.t('userProfileEditForm.updateBtn')}
              </button>
              <button className='confirm-user-deletion' type='button' onClick={onCancelHandler}>
                {i18n.t('createClientModal.decline')}
              </button>
            </div>
          </form>
        )}
      />
    </>
  );
};

export default CreateClientLabelForm;
