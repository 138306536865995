import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';
import Header from './header/Header';
import SideMenu from './sideMenu/SideMenu';
import { setCurrentUserData } from '../../../app/features/users';
import cms from '../../../app/api/cms';
import { FULL_AUTH_ROUTES } from '../../../app/routes/routeLinks';
import { slide as Menu } from 'react-burger-menu';
import './CmsLayout.scss';

const CmsLayout = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.users.currentUser);
  const goToLoginPage = useLocation();
  const [showMobile, setShowMobile] = useState(false);
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    window.addEventListener('resize', onWindowResizeHandler);

    // cleanup this component
    return () => {
      window.removeEventListener('resize', onWindowResizeHandler);
    };
  }, []);

  const onWindowResizeHandler = () => {
    let isMobile = window.matchMedia('(max-width: 1200px)');
    if (isMobile.matches === true) {
      setShowMobile(true);
    } else {
      setShowMobile(false);
    }
  };

  useEffect(() => {
    async function fetchData() {
      await cms
        .GetUser()
        .then(function (res) {
          if (res.data.data !== undefined) {
            dispatch(setCurrentUserData(res.data.data));
            console.log(res.data.data);
          }
        })
        .catch(function (error) {
          console.log('getCurrentUser error->', error);
          goToLoginPage(FULL_AUTH_ROUTES.loginPage);
        });
    }
    fetchData();
    onWindowResizeHandler();
  }, []);

  const handleIsOpen = () => {
    setOpen(!isOpen);
  };

  const closeSideBar = () => {
    setOpen(false);
  };

  function MainHtml() {
    if (showMobile === false) {
      return (
        <div className='main-cms'>
          <div className='left-side'>
            <SideMenu closeSideBar={closeSideBar} />
          </div>

          <div className='right-side'>
            <Header />
            <Outlet />
          </div>
        </div>
      );
    } else {
      return (
        <div className='main-cms' id='outer-container'>
          <Menu
            menuClassName={'custum-menu'}
            pageWrapId={'page-wrap'}
            outerContainerId={'outer-container'}
            isOpen={isOpen}
            onOpen={handleIsOpen}
            onClose={handleIsOpen}
          >
            <div className='left-side'>
              <SideMenu closeSideBar={closeSideBar} />
            </div>
          </Menu>

          <div className='right-side'>
            <span id='page-wrap'>
              <Header />
              <Outlet />
            </span>
          </div>
        </div>
      );
    }
  }

  return <>{currentUser !== null && MainHtml()}</>;
};

export default CmsLayout;
