import { createSlice } from '@reduxjs/toolkit';

export const SubmissionsReducer = createSlice({
    name: 'submissions',
    initialState: {
        statuses: [],
    },
    reducers: {
        setStatuses: (state, { payload }) => {
            state.statuses = payload;
        },
    },
});

export const { setStatuses } = SubmissionsReducer.actions;
export default SubmissionsReducer.reducer;
