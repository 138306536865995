import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Alert } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import PageTitle from '../../../components/pageTitle/PageTitle';
import { setLabels } from '../../../app/features/clients';
import { Spinner } from 'reactstrap';
import cms from '../../../app/api/cms';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';

const ClientsTable = ({ dashboardData }) => {
  const dispatch = useDispatch();
  const labels = useSelector((state) => state.clients.labels);
  const [loadingLabel, setLoadingLabel] = useState(true);

  useEffect(() => {
    if (labels && labels.length === 0) {
      loadLabels();
    } else {
      setLoadingLabel(false);
    }
  }, []);

  function isItCompany(item) {
    if (item.is_company === 1) {
      return <span>{item.company_name !== null ? item.company_name : '-'}</span>;
    } else {
      return (
        <span>
          {item.name} {item.surname !== null ? item.surname : '-'}
        </span>
      );
    }
  }

  const loadLabels = async () => {
    await cms
      .GetClientLabelsForSelect()
      .then(function (response) {
        console.log('samarliakas');
        if (response.data.data !== undefined) {
          dispatch(setLabels(response.data.data));
          setLoadingLabel(false);
        }
      })
      .catch(function (error) {
        console.log(error, 'error');
      });
  };

  return (
    <div className='all-clients-page dashboard'>
      <div className='table-box'>
        {loadingLabel === true ? (
          <Spinner>Loading...</Spinner>
        ) : (
          <>
            {dashboardData && dashboardData.length > 0 ? (
              <>
                <div className='table-all'>
                  <PageTitle>Seniausi klientai</PageTitle>
                  <div className='table-row main'>
                    <div style={{ width: '5%' }}>
                      <h6>ID</h6>
                    </div>
                    <div style={{ width: '15%' }}>
                      <h6>Žyma</h6>
                    </div>
                    <div style={{ width: '30%' }}>
                      <h6>Klientas</h6>
                    </div>
                    <div style={{ width: '20%' }}>
                      <h6>Adresas</h6>
                    </div>
                    <div style={{ width: '20%' }}>
                      <h6>Įrenginys</h6>
                    </div>
                    <div style={{ width: '10%' }}>
                      <h6>Veiksmai</h6>
                    </div>
                  </div>
                  {dashboardData.map((item, i) => (
                    <div
                      className='table-row'
                      key={`${item.id}-clients`}
                      style={
                        item.client_label_id !== null
                          ? { backgroundColor: `${item.client_label.color_hex}` }
                          : {}
                      }
                    >
                      <div style={{ width: '5%' }} className='mobile-style'>
                        <h6 className='mobile-titles'>ID</h6>
                        <p>{item.id}</p>
                      </div>
                      <div style={{ width: '15%' }} className='mobile-style'>
                        <h6 className='mobile-titles'>ŽYMA</h6>
                        <p>
                          {item.client_label_id !== null ? item.client_label.title : '-'} <br />
                          {item.last_label_change !== null ? item.last_label_change.created_at : ''}
                        </p>
                      </div>
                      <div style={{ width: '30%' }} className='mobile-style'>
                        <h6 className='mobile-titles'>KLIENTAS</h6>
                        <p>
                          {isItCompany(item)}
                          <br />
                          <FontAwesomeIcon icon={faEnvelope} /> {item.email}
                          <br />
                          <FontAwesomeIcon icon={faPhone} /> {item.phone}
                        </p>
                      </div>
                      <div style={{ width: '20%' }} className='mobile-style'>
                        <h6 className='mobile-titles'>ADRESAS</h6>
                        <p>
                          {item.settlement !== null ? item.settlement : '-'}
                          {item.street !== null ? item.street : '-'}
                          {item.property_number !== null ? item.property_number : '-'}
                        </p>
                      </div>
                      <div style={{ width: '20%' }} className='mobile-style'>
                        <h6 className='mobile-titles'>ĮRENGINYS</h6>
                        <p>{item.device !== null ? item.device : '-'}</p>
                      </div>
                      <div
                        style={{ width: '10%' }}
                        className='d-flex flex-column align-items-center mobile-style'
                      >
                        <Link to={`/cms/admin/clients/${item.id}/review`} className='seen-btn'>
                          <span>Peržiūrėti</span>
                        </Link>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            ) : (
              <Alert style={{ marginLeft: '20px', marginRight: '20px' }} color='primary'>
                Klientų nera.
              </Alert>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ClientsTable;
