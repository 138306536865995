import { useEffect, useState } from 'react';
import { Spinner } from 'reactstrap';
import PageTitle from '../../../components/pageTitle/PageTitle';
import { setLabels } from '../../../app/features/clients';
import cms from '../../../app/api/cms';
import { useSelector, useDispatch } from 'react-redux';
import AddMyClientForm from './AddMyClientForm';
import './AddMyClientPage.scss';

const AddMyClient = () => {
    const dispatch = useDispatch();
    const labels = useSelector((state) => state.clients.labels);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        loadLabels();
    }, []);

    const loadLabels = async () => {
        await cms
            .GetClientLabelsForSelect()
            .then(function (response) {
                if (response.data.data !== undefined) {
                    dispatch(setLabels(response.data.data));
                    setLoading(false);
                }
            })
            .catch(function (error) {
                console.log(error, 'error');
            });
    };

    return (
        <div className='add-my-client-page'>
            <PageTitle>Pridėti naują klientą</PageTitle>
            <div className='add-my-client-form'>{loading && loading === true ? <Spinner>Loading...</Spinner> : <AddMyClientForm labels={labels} />}</div>
        </div>
    );
};

export default AddMyClient;
