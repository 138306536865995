import axios from 'axios';
const mainApiUrl = process.env.REACT_APP_API_ROOT;
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

const auth = {
  Login,
};
export default auth;

async function Login(fields) {
  let response = await axios.post(`${mainApiUrl}/cms/auth/login`, fields);
  return response;
}
