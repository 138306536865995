import PageTitle from '../../../components/pageTitle/PageTitle';
import AddClientForm from './AddClientForm';
import './AddClient.scss';

const AddClient = () => {
  return (
    <div className='add-client-page'>
      <PageTitle>Pridėti naują klientą</PageTitle>
      <div className='add-client-form'>
        <AddClientForm />
      </div>
    </div>
  );
};

export default AddClient;
