import { useState } from 'react';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import i18n from '../../../app/i18n';
import cms from '../../../app/api/cms';
import './RecallModal.scss';

const RecallModal = ({ recallId, recallData }) => {
  const [recallModalOpen, setRecallModalOpen] = useState(false);
  const [startDate, setStartDate] = useState(
    recallData !== null ? new Date(recallData) : new Date(),
  );
  const [errorMessage, setErrorMessage] = useState('');

  const onClickHandler = async () => {
    let formData = new FormData();

    const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    formData.append(
      'callback_date',
      startDate ? new Date(startDate).toLocaleDateString('lt-LT', options) : '',
    );

    await cms
      .WebSubmissionNeedCallback(recallId, formData)
      .then(function (response) {
        if (response.data.data !== undefined) {
          setRecallModalOpen(false);
          window.location.reload();
        }
      })
      .catch(function (error) {
        if (error.response.data.error !== undefined) {
          setErrorMessage(i18n.t(`formErrors.${error.response.data.error}`));
        }
      });
  };

  const onCancelHandler = () => {
    setRecallModalOpen(false);
  };

  function recallModal() {
    return (
      <Modal
        className='delete-user-model'
        centered={true}
        isOpen={recallModalOpen}
        toggle={onCancelHandler}
      >
        <ModalHeader charcode='Y' toggle={() => setRecallModalOpen(false)}>
          <h6>Pasirinkite perskambinimo datą</h6>
        </ModalHeader>
        <ModalBody>
          <p>{errorMessage}</p>
          <DatePicker
            dateFormat='yyyy-MM-dd'
            selected={startDate}
            onChange={(date) => setStartDate(date)}
          />
        </ModalBody>
        <ModalFooter className='d-flex justify-content-between '>
          <button onClick={onClickHandler} className='cancel-user-deletion'>
            {i18n.t('recallModal.save')}
          </button>
          <button className='confirm-user-deletion' onClick={onCancelHandler}>
            {i18n.t('recallModal.decline')}
          </button>
        </ModalFooter>
      </Modal>
    );
  }

  return (
    <>
      {recallModal()}
      <button className='recall-btn' onClick={() => setRecallModalOpen(true)}>
        Perskambinti
      </button>
    </>
  );
};

export default RecallModal;
