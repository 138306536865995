import { useState } from 'react';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import i18n from '../../../app/i18n';
import '../assignMe/AssignMeModal.scss';

const AssignClientToMeModal = ({ assignHandler }) => {
    const [assignMeModalOpen, setAssignMeModalOpen] = useState(false);

    const onClickHandler = () => {
        assignHandler();
    };

    const onCancelHandler = () => {
        setAssignMeModalOpen(false);
    };

    function assignArchiveModal() {
        return (
            <Modal className='delete-user-model' centered={true} isOpen={assignMeModalOpen} toggle={onCancelHandler}>
                <ModalHeader charcode='Y' toggle={() => setAssignMeModalOpen(false)}></ModalHeader>
                <ModalBody>
                    <h6>{i18n.t('asignMeModal.sureAssignMe')}</h6>
                </ModalBody>
                <ModalFooter className='d-flex justify-content-between'>
                    <button onClick={onClickHandler} className='cancel-user-deletion'>
                        {i18n.t('asignMeModal.assign')}
                    </button>
                    <button className='confirm-user-deletion' onClick={onCancelHandler}>
                        {i18n.t('asignMeModal.decline')}
                    </button>
                </ModalFooter>
            </Modal>
        );
    }

    return (
        <>
            {assignArchiveModal()}
            <a onClick={() => setAssignMeModalOpen(true)}>Priskirti man</a>
        </>
    );
};

export default AssignClientToMeModal;
