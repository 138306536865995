import { useState } from 'react';
import { Link } from 'react-router-dom';
import PaginationGolbal from '../../../components/pagination/PaginationGlobal';
import MyWebSubmissionsFilter from './MyWebSubmissionsFilter';
import PageTitle from '../../../components/pageTitle/PageTitle';
import './MyWebsubmissionPage.scss';

const MyWebsubmissionPage = () => {
    const [currentPage, setCurrentPage] = useState(1);

    const handlePageChange = (newActivePage) => {
        setCurrentPage(newActivePage);
    };

    return (
        <div className='my-web-submission-page'>
            <PageTitle>Mano bendros užklausos</PageTitle>
            <div className='table-box'>
                <div className='upper-side with-filter'>
                    <MyWebSubmissionsFilter />
                </div>
                <div className='table-all'>
                    <div className='table-row main'>
                        <div style={{ width: '10%' }}>
                            <h6>ID</h6>
                        </div>
                        <div style={{ width: '10%' }}>
                            <h6>Sukurta</h6>
                        </div>
                        <div style={{ width: '20%' }}>
                            <h6>Statusas</h6>
                        </div>
                        <div style={{ width: '30%' }}>
                            <h6>Klientas</h6>
                        </div>
                        <div style={{ width: '20%' }}>
                            <h6>Perskambinimo data</h6>
                        </div>
                        <div style={{ width: '10%' }}>
                            <h6>Veiksmai</h6>
                        </div>
                    </div>
                    <div className='table-row row-new'>
                        <div style={{ width: '10%' }}>
                            <p>123456</p>
                        </div>
                        <div style={{ width: '10%' }}>
                            <p>2020 10 20 10:29</p>
                        </div>
                        <div style={{ width: '20%' }}>
                            <p>geras statusas</p>
                        </div>
                        <div style={{ width: '30%' }}>
                            <p>
                                Edwina Baldetti3r4r3r3r3
                                <br />
                                +37061020386
                            </p>
                        </div>
                        <div style={{ width: '20%' }}>
                            <p>2020 10 20 10:29</p>
                        </div>
                        <div style={{ width: '10%' }} className='d-flex flex-column align-items-center'>
                            <Link to='/cms/admin/my-web-submissions/:rowID/review' className='seen-btn'>
                                <span>Peržiūrėti</span>
                            </Link>
                        </div>
                    </div>
                    <div className='table-row row-in_review'>
                        <div style={{ width: '10%' }}>
                            <p>123456</p>
                        </div>
                        <div style={{ width: '10%' }}>
                            <p>2020 10 20 10:29</p>
                        </div>
                        <div style={{ width: '20%' }}>
                            <p>geras statusas</p>
                        </div>
                        <div style={{ width: '30%' }}>
                            <p>
                                Edwina Baldetti3r4r3r3r3
                                <br />
                                +37061020386
                            </p>
                        </div>
                        <div style={{ width: '20%' }}>
                            <p>2020 10 20 10:29</p>
                        </div>
                        <div style={{ width: '10%' }} className='d-flex flex-column align-items-center'>
                            <Link to='/cms/admin/my-web-submissions/:rowID/review' className='seen-btn'>
                                <span>Peržiūrėti</span>
                            </Link>
                        </div>
                    </div>
                    <div className='table-row row-reviewed'>
                        <div style={{ width: '10%' }}>
                            <p>123456</p>
                        </div>
                        <div style={{ width: '10%' }}>
                            <p>2020 10 20 10:29</p>
                        </div>
                        <div style={{ width: '20%' }}>
                            <p>geras statusas</p>
                        </div>
                        <div style={{ width: '30%' }}>
                            <p>
                                Edwina Baldetti3r4r3r3r3
                                <br />
                                +37061020386
                            </p>
                        </div>
                        <div style={{ width: '20%' }}>
                            <p>2020 10 20 10:29</p>
                        </div>
                        <div style={{ width: '10%' }} className='d-flex flex-column align-items-center'>
                            <Link to='/cms/admin/my-web-submissions/:rowID/review' className='seen-btn'>
                                <span>Peržiūrėti</span>
                            </Link>
                        </div>
                    </div>
                    <div className='table-row row-need_callback'>
                        <div style={{ width: '10%' }}>
                            <p>123456</p>
                        </div>
                        <div style={{ width: '10%' }}>
                            <p>2020 10 20 10:29</p>
                        </div>
                        <div style={{ width: '20%' }}>
                            <p>geras statusas</p>
                        </div>
                        <div style={{ width: '30%' }}>
                            <p>
                                Edwina Baldetti3r4r3r3r3
                                <br />
                                +37061020386
                            </p>
                        </div>
                        <div style={{ width: '20%' }}>
                            <p>2020 10 20 10:29</p>
                        </div>
                        <div style={{ width: '10%' }} className='d-flex flex-column align-items-center'>
                            <Link to='/cms/admin/my-web-submissions/:rowID/review' className='seen-btn'>
                                <span>Peržiūrėti</span>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className='pagination-container'>
                    <PaginationGolbal activePage={currentPage} totalItemsCount={100} handlePageChange={handlePageChange} />
                </div>
            </div>
        </div>
    );
};

export default MyWebsubmissionPage;
