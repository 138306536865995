import i18n from 'i18n-js';
import langLT from './langLT';

i18n.fallbacks = true;
i18n.locale = 'lt';

i18n.translations = {
    lt: langLT,
};

export default i18n;
