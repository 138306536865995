import './InputFieldWithIcon.scss';

const InputFieldWithIcon = (props) => {
    return (
        <>
            {props.children}
            <div className='input-box'>
                {props.label ? (
                    <label htmlFor={props.input.name} className='input-label'>
                        {props.label}
                    </label>
                ) : (
                    <></>
                )}
                <input
                    type={props.type ? props.type : 'text'}
                    id={props.input.name}
                    className={`form-control ${(props.meta.error || props.meta.submitError) && props.meta.touched ? 'is-invalid' : ''}`}
                    placeholder={props.placeholder}
                    disabled={props.disabled ? true : false}
                    {...props.input}
                />

                {(props.meta.error || props.meta.submitError) && props.meta.touched && <div className='invalid-feedback'>{props.meta.error || props.meta.submitError}</div>}
            </div>
        </>
    );
};

export default InputFieldWithIcon;
