import React from 'react';
import PageTitle from '../../../../components/pageTitle/PageTitle';
import './ClientProfileReviewPage.scss';

const ReviewBlock = ({ client }) => {
  return (
    <div className='review-sub-page' style={{ marginTop: '8px' }}>
      <PageTitle>Kliento peržiūra</PageTitle>

      <div className='table-box'>
        <div className='upper-side with-filter'>
          <h6>Klientas ID: #{client.id}</h6>
        </div>
        <div className='table-all d-flex'>
          <div className='table-row d-flex flex-column border'>
            <div className='d-flex border-bottom align-items-center p-2 mob-style-client-rev'>
              <div style={{ width: '30%' }} className='wid-100-client-rev'>
                <h6>Sukurta</h6>
              </div>
              <div style={{ width: '70%' }} className='wid-100-client-rev'>
                <p>{client.created_at}</p>
              </div>
            </div>

            <div className='d-flex border-bottom align-items-center p-2 mob-style-client-rev'>
              <div style={{ width: '30%' }} className='wid-100-client-rev'>
                <h6>Žyma</h6>
              </div>
              <div style={{ width: '70%' }} className='wid-100-client-rev'>
                <p>
                  {client.client_label_id !== null ? client.client_label.title : '-'}{' '}
                  {client.last_label_change !== null ? client.last_label_change.created_at : ''}
                </p>
              </div>
            </div>

            <div className='d-flex border-bottom align-items-center p-2 mob-style-client-rev'>
              <div style={{ width: '30%' }} className='wid-100-client-rev'>
                <h6>Vardas Pavardė</h6>
              </div>
              <div style={{ width: '70%' }} className='wid-100-client-rev'>
                <p>
                  {client.name} {client.surname !== null ? client.surname : '-'}
                </p>
              </div>
            </div>
            <div className='d-flex border-bottom align-items-center p-2 mob-style-client-rev'>
              <div style={{ width: '30%' }} className='wid-100-client-rev'>
                <h6>Telefonas</h6>
              </div>
              <div style={{ width: '70%' }} className='wid-100-client-rev'>
                <p>{client.phone !== null ? client.phone : '-'}</p>
              </div>
            </div>

            <div className='d-flex border-bottom align-items-center p-2 mob-style-client-rev'>
              <div style={{ width: '30%' }} className='wid-100-client-rev'>
                <h6>El. paštas</h6>
              </div>
              <div style={{ width: '70%' }} className='wid-100-client-rev'>
                <p>{client.email !== null ? client.email : '-'}</p>
              </div>
            </div>

            <div className='d-flex border-bottom align-items-center p-2 mob-style-client-rev'>
              <div style={{ width: '30%' }} className='wid-100-client-rev'>
                <h6>Adresas</h6>
              </div>
              <div style={{ width: '70%' }} className='wid-100-client-rev'>
                <p>
                  {client.settlement !== null ? client.settlement : '-'}
                  {client.street !== null ? client.street : '-'}
                  {client.property_number !== null ? client.property_number : '-'}
                </p>
              </div>
            </div>

            <div className='d-flex border-bottom align-items-center p-2 mob-style-client-rev'>
              <div style={{ width: '30%' }} className='wid-100-client-rev'>
                <h6>Įrenginys</h6>
              </div>
              <div style={{ width: '70%' }} className='wid-100-client-rev'>
                <p>{client.device !== null ? client.device : '-'}</p>
              </div>
            </div>

            <div className='d-flex border-bottom align-items-center p-2 mob-style-client-rev'>
              <div style={{ width: '30%' }} className='wid-100-client-rev'>
                <h6>
                  Papildomi įrenginiai /<br /> ištekėjimas
                </h6>
              </div>
              <div style={{ width: '70%' }} className='wid-100-client-rev'>
                <p>{client.additional_device !== null ? client.additional_device : '-'}</p>
              </div>
            </div>
            <div className='d-flex border-bottom align-items-center p-2 mob-style-client-rev'>
              <div style={{ width: '30%' }} className='wid-100-client-rev'>
                <h6>Komentaras</h6>
              </div>
              <div style={{ width: '70%' }} className='wid-100-client-rev'>
                <p>{client.comment !== null ? client.comment : '-'}</p>
              </div>
            </div>
            <div className='d-flex border-bottom align-items-center p-2 mob-style-client-rev'>
              <div style={{ width: '30%' }} className='wid-100-client-rev'>
                <h6>Įrenginio kaina</h6>
              </div>
              <div style={{ width: '70%' }} className='wid-100-client-rev'>
                <p>{client.device_price !== null ? client.device_price : '-'}</p>
              </div>
            </div>
            <div className='d-flex border-bottom align-items-center p-2 mob-style-client-rev'>
              <div style={{ width: '30%' }} className='wid-100-client-rev'>
                <h6>Avansas</h6>
              </div>
              <div style={{ width: '70%' }} className='wid-100-client-rev'>
                <p>{client.advance_payment !== null ? client.advance_payment : '-'}</p>
              </div>
            </div>
            <div className='d-flex border-bottom align-items-center p-2 mob-style-client-rev'>
              <div style={{ width: '30%' }} className='wid-100-client-rev'>
                <h6>Vamzdyno metro kaina</h6>
              </div>
              <div style={{ width: '70%' }} className='wid-100-client-rev'>
                <p>{client.pipeline_price !== null ? client.pipeline_price : '-'}</p>
              </div>
            </div>
            <div className='d-flex border-bottom align-items-center p-2 mob-style-client-rev'>
              <div style={{ width: '30%' }} className='wid-100-client-rev'>
                <h6>Atlikimo data</h6>
              </div>
              <div style={{ width: '70%' }} className='wid-100-client-rev'>
                <p>{client.completion_date !== null ? client.completion_date : '-'}</p>
              </div>
            </div>
            <div className='d-flex border-bottom align-items-center p-2 mob-style-client-rev'>
              <div style={{ width: '30%' }} className='wid-100-client-rev'>
                <h6>Įmonės pavadinimas</h6>
              </div>
              <div style={{ width: '70%' }} className='wid-100-client-rev'>
                <p>{client.company_name !== null ? client.company_name : '-'}</p>
              </div>
            </div>
            <div className='d-flex border-bottom align-items-center p-2 mob-style-client-rev'>
              <div style={{ width: '30%' }} className='wid-100-client-rev'>
                <h6>Įmonės PVM kodas</h6>
              </div>
              <div style={{ width: '70%' }} className='wid-100-client-rev'>
                <p>{client.company_vat !== null ? client.company_vat : '-'}</p>
              </div>
            </div>
            <div className='d-flex border-bottom align-items-center p-2 mob-style-client-rev'>
              <div style={{ width: '30%' }} className='wid-100-client-rev'>
                <h6>Įmonės kodas</h6>
              </div>
              <div style={{ width: '70%' }} className='wid-100-client-rev'>
                <p>{client.company_code !== null ? client.company_code : '-'}</p>
              </div>
            </div>
            <div className='d-flex align-items-center p-2 mob-style-client-rev'>
              <div style={{ width: '30%' }} className='wid-100-client-rev'>
                <h6>Galutinė suma</h6>
              </div>
              <div style={{ width: '70%' }} className='wid-100-client-rev'>
                <p>{client.grand_total_price !== null ? client.grand_total_price : '-'}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewBlock;
