import { Badge } from 'reactstrap';
const StatusBadge = ({ status, text }) => {
    let statusClass = '';

    if (status === 'new') {
        statusClass = 'secondary';
    } else if (status === 'sent') {
        statusClass = 'info';
    } else if (status === 'confirmed') {
        statusClass = 'success';
    } else if (status === 'rejected') {
        statusClass = 'danger';
    } else {
        statusClass = 'secondary';
    }

    return <Badge color={statusClass}>{text}</Badge>;
};

export default StatusBadge;
