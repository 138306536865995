import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Field } from 'react-final-form';
import { setNewFilter } from '../../../app/features/clients';
import { SelectField, InputFieldWithIcon } from '../../../components/form';
import i18n from '../../../app/i18n';

const ClientsFilter = ({ currentFilterFields }) => {
  const dispatch = useDispatch();
  const [formFieldValues] = useState(currentFilterFields);

  const validate = (values) => {
    const errors = {};

    return errors;
  };

  const onSubmit = async (values) => {
    if (values.searchString !== undefined) {
      dispatch(setNewFilter(values));
    } else {
      dispatch(setNewFilter({ searchString: '', archivated: false }));
    }
  };

  const formFields = [
    {
      field: (
        <Field name='searchString'>{({ input, meta }) => <InputFieldWithIcon placeholder={i18n.t('clientsFilter.filterByNameSurnameAndPhone')} input={input} meta={meta}></InputFieldWithIcon>}</Field>
      ),
    },
  ];

  return (
    <>
      <Form
        onSubmit={onSubmit}
        initialValues={formFieldValues}
        validate={validate}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} className='select-label-form' noValidate>
            <div className='row'>
              <div className='col-8'>
                {formFields.map((item, key) => (
                  <div key={`${key}-fields`}>{item.field}</div>
                ))}
              </div>
              <div className='col-4'>
                <button className='seen-btn' type='submit'>
                  Filtruoti
                </button>
              </div>
            </div>
          </form>
        )}
      />
    </>
  );
};

export default ClientsFilter;
