import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ClientProfileHeader from '../../../../components/userProfile/header/ClientProfileHeader';
import './ClientProfileReviewPage.scss';
import cms from '../../../../app/api/cms';
import { Spinner } from 'reactstrap';
import ReviewBlock from './ReviewBlock';

const ClientProfileReviewPage = () => {
    const { rowID } = useParams();
    const [client, setClient] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        loadClient();
    }, []);

    const loadClient = async () => {
        setLoading(true);
        setClient(null);

        await cms
            .GetClient(rowID)
            .then(function (response) {
                if (response.data.data !== undefined) {
                    setClient(response.data.data);
                    setLoading(false);
                }
            })
            .catch(function (error) {});
    };

    return (
        <>
            {loading === true ? (
                <Spinner>Loading...</Spinner>
            ) : (
                <>
                    <ClientProfileHeader client={client} />
                    <ReviewBlock client={client} />
                </>
            )}
        </>
    );
};

export default ClientProfileReviewPage;
