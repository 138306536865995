import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import PageTitle from '../../../components/pageTitle/PageTitle';
import PaginationGolbal from '../../../components/pagination/PaginationGlobal';
import ClientsFilter from './ClientsFilter';
import ClientArchiveModal from '../../../components/modals/clientArchive/ClientArchiveModal';
import cms from '../../../app/api/cms';
import { setLabels } from '../../../app/features/clients';
import { Spinner } from 'reactstrap';
import './AllClientsPage.scss';
import { FULL_ADMIN_ROUTES } from '../../../app/routes/routeLinks';
import { Alert } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';

const AllClientsPage = () => {
  const dispatch = useDispatch();
  const labels = useSelector((state) => state.clients.labels);
  const currentFilter = useSelector((state) => state.clients.filterFields);
  const [loadingLabel, setLoadingLabel] = useState(true);
  const [loadingRows, setLoadingRows] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [rows, setRows] = useState([]);
  const [totalItemsCount, setTotalItemsCount] = useState(0);

  useEffect(() => {
    if (labels && labels.length === 0) {
      loadLabels();
    }
    loadRows(currentPage, currentFilter);
  }, [currentPage, currentFilter]);

  const loadRows = async (currentPageIndex, currentFilter) => {
    setRows([]);

    await cms
      .GetClients(currentPageIndex, currentFilter)
      .then(function (response) {
        if (response.data.data !== undefined) {
          setRows(response.data.data.rows);
          setTotalItemsCount(response.data.data.total_count);
          setLoadingRows(false);
        }
      })
      .catch(function (error) {});
  };

  const loadLabels = async () => {
    await cms
      .GetClientLabelsForSelect()
      .then(function (response) {
        if (response.data.data !== undefined) {
          dispatch(setLabels(response.data.data));
          setLoadingLabel(false);
        }
      })
      .catch(function (error) {
        console.log(error, 'error');
      });
  };

  const handlePageChange = (newActivePage) => {
    setCurrentPage(newActivePage);
  };

  function isItCompany(item) {
    if (item.is_company === 1) {
      return <span>{item.company_name !== null ? item.company_name : '-'}</span>;
    } else {
      return (
        <span>
          {item.name} {item.surname !== null ? item.surname : '-'}
        </span>
      );
    }
  }

  return (
    <div className='all-clients-page'>
      <PageTitle>Klientai</PageTitle>
      <div className='table-box'>
        <div className='upper-side with-filter'>
          <ClientsFilter currentFilterFields={currentFilter} />

          <div>
            <Link to={FULL_ADMIN_ROUTES.addMyClientsPage}>Pridėti klientą</Link>
          </div>
        </div>
        {loadingLabel === true && loadingRows === true ? (
          <Spinner>Loading...</Spinner>
        ) : (
          <>
            {rows && rows.length > 0 ? (
              <>
                <div className='table-all'>
                  <div className='table-row main'>
                    <div style={{ width: '5%' }}>
                      <h6>ID</h6>
                    </div>
                    <div style={{ width: '15%' }}>
                      <h6>Žyma</h6>
                    </div>
                    <div style={{ width: '30%' }}>
                      <h6>Klientas</h6>
                    </div>
                    <div style={{ width: '20%' }}>
                      <h6>Adresas</h6>
                    </div>
                    <div style={{ width: '20%' }}>
                      <h6>Įrenginys</h6>
                    </div>
                    <div style={{ width: '10%' }} className='mob-gone'>
                      <h6>Veiksmai</h6>
                    </div>
                  </div>

                  {rows.map((item, i) => (
                    <div className='table-row' key={`${item.id}-clients`} style={item.client_label_id !== null ? { backgroundColor: `${item.client_label.color_hex}` } : {}}>
                      <div style={{ width: '5%' }} className='mobile-style'>
                        <h6 className='mobile-titles'>ID</h6>
                        <p>{item.id}</p>
                      </div>
                      <div style={{ width: '15%' }} className='mobile-style'>
                        <h6 className='mobile-titles'>ŽYMA</h6>

                        <p>
                          {item.client_label_id !== null ? item.client_label.title : '-'} <br />
                          {item.last_label_change !== null ? item.last_label_change.created_at : ''}
                        </p>
                      </div>
                      <div style={{ width: '30%' }} className='mobile-style'>
                        <h6 className='mobile-titles'>KLIENTAS</h6>
                        <p>
                          {isItCompany(item)}
                          <br />
                          <FontAwesomeIcon icon={faEnvelope} />
                          {item.email !== null ? item.email : '-'}
                          <br />
                          <FontAwesomeIcon icon={faPhone} />
                          {item.phone !== null ? item.phone : '-'}
                        </p>
                      </div>
                      <div style={{ width: '20%' }} className='mobile-style'>
                        <h6 className='mobile-titles'>ADRESAS</h6>
                        <p>
                          {item.settlement !== null ? item.settlement : '-'}{' '}
                          {item.street !== null ? item.street : '-'}{' '}
                          {item.property_number !== null ? item.property_number : '-'}
                        </p>
                      </div>
                      <div style={{ width: '20%' }} className='mobile-style'>
                        <h6 className='mobile-titles'>ĮRENGINYS</h6>
                        <p>{item.device !== null ? item.device : '-'}</p>
                      </div>
                      <div style={{ width: '10%' }} className='d-flex flex-column align-items-center mobile-style mobile-btn pt-2'>
                        <Link to={`/cms/admin/clients/${item.id}/review`} className='seen-btn'>
                          <span>Peržiūrėti</span>
                        </Link>
                        <ClientArchiveModal clientId={item.id} />
                      </div>
                    </div>
                  ))}
                </div>
                <div className='pagination-container'>
                  <PaginationGolbal activePage={currentPage} totalItemsCount={totalItemsCount} handlePageChange={handlePageChange} />
                </div>
              </>
            ) : (
              <Alert style={{ marginLeft: '20px', marginRight: '20px' }} color='primary'>
                Klientų nera.
              </Alert>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default AllClientsPage;
