import { createSlice } from '@reduxjs/toolkit';

export const ClientsReducer = createSlice({
  name: 'clients',
  initialState: {
    listForForm: [],
    labels: [],
    filterFields: {
      searchString: '',
      archivated: false,
    },
  },
  reducers: {
    setListForForm: (state, { payload }) => {
      state.listForForm = payload;
    },
    setLabels: (state, { payload }) => {
      state.labels = payload;
    },
    setNewFilter: (state, { payload }) => {
      state.filterFields = payload;
    },
  },
});

export const { setListForForm, setLabels, setNewFilter } = ClientsReducer.actions;
export default ClientsReducer.reducer;
