import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import { configureStore } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';
import UserReducer from './app/features/users';
import EowsReducer from './app/features/eows';
import ServicesReducer from './app/features/services';
import BasicConfigsReducer from './app/features/basicConfigs';
import ClientsReducer from './app/features/clients';
import SubmissionsReducer from './app/features/submissions';
import InvocesReducer from './app/features/invoices';

import './index.scss';
import App from './app/App';

const store = configureStore({
    reducer: {
        users: UserReducer,
        eows: EowsReducer,
        basicConfigs: BasicConfigsReducer,
        services: ServicesReducer,
        clients: ClientsReducer,
        submissions: SubmissionsReducer,
        invoices: InvocesReducer,
    },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <App />
    </Provider>,
);
