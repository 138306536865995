import React, { useState } from 'react';
import { Form, Field } from 'react-final-form';
import { SelectField } from '../../../components/form';
import i18n from '../../../app/i18n';

const WebSubmissionsFilter = () => {
    const [formFieldValues] = useState(null);

    const validate = (values) => {
        const errors = {};

        return errors;
    };

    const onSubmit = async (values) => {};

    const formFields = [
        {
            field: (
                <Field name='label'>
                    {({ select, meta }) => (
                        <SelectField select={select} meta={meta} label={i18n.t('webSubmissions.selectStatus')}>
                            <option value=''>All</option>
                            <option value='eEEEEEe'>eEEEEEe</option>
                            <option value='QQQQQ'>QQQQQ</option>
                        </SelectField>
                    )}
                </Field>
            ),
        },
    ];
    return (
        <>
            <Form
                onSubmit={onSubmit}
                initialValues={formFieldValues}
                validate={validate}
                render={({ handleSubmit }) => (
                    <form onSubmit={handleSubmit} className='select-label-form' noValidate>
                        {formFields.map((item, key) => (
                            <div key={`${key}-fields`}>{item.field}</div>
                        ))}
                    </form>
                )}
            />
        </>
    );
};

export default WebSubmissionsFilter;
