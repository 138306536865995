import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import cms from '../../../app/api/cms';
import EditUserForm from './EditUserForm';
import PageTitle from '../../../components/pageTitle/PageTitle';
import './EditUser.scss';

const EditUser = () => {
  const [editUserData, setEditUserData] = useState(null);
  const [loadData, setLoadData] = useState(false);
  const { userID } = useParams();

  useEffect(() => {
    cms
      .GetUserData(userID)
      .then(function (response) {
        if (response.data.data !== undefined) {
          setEditUserData(response.data.data);
          setLoadData(true);
        }
      })
      .catch(function (error) {
        console.log(error, 'error');
      });
  }, []);

  return (
    <div className='edit-user-page'>
      <PageTitle>Redaguoti vartotoją</PageTitle>
      <div className='edit-user-form'>
        {loadData && <EditUserForm editUserData={editUserData} />}
      </div>
    </div>
  );
};

export default EditUser;
