import { useEffect, useState } from 'react';
import PageTitle from '../../../../components/pageTitle/PageTitle';
import i18n from '../../../../app/i18n';
import { useSelector, useDispatch } from 'react-redux';
import cms from '../../../../app/api/cms';
import { setFields } from '../../../../app/features/basicConfigs';
import { setDefaults } from '../../../../app/features/services';
import { setListForForm } from '../../../../app/features/clients';
import { Spinner } from 'reactstrap';
import AddForm from './AddForm';
import './AddForm.scss';
import React from 'react';

const EowCreatePage = () => {
    const dispatch = useDispatch();
    const basicFields = useSelector((state) => state.basicConfigs.fields);
    const defaultServices = useSelector((state) => state.services.defaults);
    const clientsForForm = useSelector((state) => state.clients.listForForm);
    const [loadedFields, setLoadedFields] = useState(false);
    const [loadedServices, setLoadedServices] = useState(false);
    const [loadedClients, setLoadedClients] = useState(false);

    useEffect(() => {
        if (basicFields && basicFields.length === 0) {
            loadBasicFields();
        } else {
            setLoadedFields(true);
        }
        if (defaultServices && defaultServices.length === 0) {
            loadServices();
        } else {
            setLoadedServices(true);
        }
        if (clientsForForm && clientsForForm.length === 0) {
            loadClients();
        } else {
            setLoadedClients(true);
        }
    }, []);

    const loadClients = async () => {
        await cms
            .GetClientsForSelect()
            .then(function (response) {
                if (response.data.data !== undefined) {
                    dispatch(setListForForm(response.data.data));
                    setLoadedClients(true);
                }
            })
            .catch(function (error) {
                console.log(error, 'error');
            });
    };

    const loadBasicFields = async () => {
        await cms
            .GetBasicFields()
            .then(function (response) {
                if (response.data.data !== undefined) {
                    dispatch(setFields(response.data.data));
                    setLoadedFields(true);
                }
            })
            .catch(function (error) {
                console.log(error, 'error');
            });
    };

    const loadServices = async () => {
        await cms
            .GetDefaultServices()
            .then(function (response) {
                if (response.data.data !== undefined) {
                    dispatch(setDefaults(response.data.data));
                    setLoadedServices(true);
                }
            })
            .catch(function (error) {
                console.log(error, 'error');
            });
    };

    return (
        <div className='add-form-page'>
            <PageTitle>{i18n.t('eowPages.newEow')}</PageTitle>
            <>{loadedFields && loadedServices && loadedClients ? <AddForm /> : <Spinner>Loading...</Spinner>}</>
        </div>
    );
};

export default EowCreatePage;
