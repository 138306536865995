import PageTitle from '../../../components/pageTitle/PageTitle';
import ProfileEditForm from './ProfileEditForm';
import './ProfilePage.scss';

const ProfilePage = () => {
  return (
    <div className='profile-page'>
      <PageTitle>Profilis</PageTitle>
      <div className='user-update-form'>
        <ProfileEditForm />
      </div>
    </div>
  );
};

export default ProfilePage;
