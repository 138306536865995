import { AUTH_ROUTES } from './routeLinks';
import { LoginPage } from '../../pages/auth';

const authUrls = [
  {
    path: AUTH_ROUTES.loginPage,
    component: <LoginPage />,
  },
];

export default authUrls;
