import React, { useState } from 'react';
import { Alert } from 'reactstrap';
import { Form, Field } from 'react-final-form';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { InputFieldWithIcon, SearchableSelect } from '../../../components/form';
import i18n from '../../../app/i18n';
import './ClientProfileAddSubmissionModal.scss';
import { useSelector } from 'react-redux';
import cms from '../../../app/api/cms';

const ClientProfileAddSubmissionForm = ({ client, onCancelHandler }) => {
  const statuses = useSelector((state) => state.submissions.statuses);
  const [formFieldValues] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [startDate, setStartDate] = useState(null);

  const onSubmit = async (values) => {
    let formData = new FormData();
    setErrorMessage('');

    if (startDate !== null) {
      const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
      formData.append(
        'callback_date',
        startDate ? new Date(startDate).toLocaleDateString('lt-LT', options) : '',
      );
    }

    if (values.status && values.status.value) {
      formData.append('status', values.status.value);
    }

    if (values.content) {
      formData.append('content', values.content);
    }
    formData.append('full_name', `${client.name} ${client.surname}`);
    formData.append('phone', `${client.phone}`);
    formData.append('email', `${client.email}`);

    let isErrors = false;
    let formErrors = {};

    await cms
      .CreateClientSubmission(client.id, formData)
      .then(function (response) {
        if (response.data.data !== undefined) {
          window.location.reload();
        }
      })
      .catch(function (error) {
        if (error.response.data.error !== undefined) {
          setErrorMessage(i18n.t(`formErrors.${error.response.data.error}`));
        }
        if (error.response.data !== undefined) {
          for (const [key, value] of Object.entries(error.response.data)) {
            formErrors[key] = i18n.t(`formError.${value}`);
          }
          isErrors = true;
        }
      });
    if (isErrors) {
      return formErrors;
    }
  };

  const validate = (values) => {
    const errors = {};

    if (!values.content) {
      errors.content = i18n.t('formErrors.requiredField');
    }

    return errors;
  };

  const formFields = [
    {
      field: (
        <Field name='status'>
          {({ input, meta }) => (
            <SearchableSelect
              placeholder={i18n.t('clientPrifileSub.selectStatus')}
              options={statuses}
              input={input}
              meta={meta}
              label={i18n.t('clientPrifileSub.selectStatus')}
            ></SearchableSelect>
          )}
        </Field>
      ),
    },
    {
      field: (
        <Field name='content'>
          {({ input, meta }) => (
            <InputFieldWithIcon
              placeholder={i18n.t('clientPrifileSub.writeComment')}
              input={input}
              meta={meta}
              label={i18n.t('clientPrifileSub.comment')}
            ></InputFieldWithIcon>
          )}
        </Field>
      ),
    },
  ];

  return (
    <>
      {errorMessage && errorMessage !== '' ? <Alert color='danger'>{errorMessage}</Alert> : ''}
      <Form
        onSubmit={onSubmit}
        initialValues={formFieldValues}
        validate={validate}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} className='user-profile-update-form' noValidate>
            <div className='row'>
              {formFields.map((item, key) => (
                <div key={`${key}-fields`}>{item.field}</div>
              ))}
            </div>
            <div className='row mb-4 p12'>
              <label className='input-label ps-0 pe-0'>Pasirinkite datą</label>
              <DatePicker
                dateFormat='yyyy-MM-dd'
                selected={startDate}
                onChange={(date) => setStartDate(date)}
              />
            </div>
            <div className='d-flex justify-content-between'>
              <button className='add-client-btn cancel-user-deletion' type='submit'>
                {i18n.t('userProfileEditForm.updateBtn')}
              </button>
              <button className='confirm-user-deletion' type='button' onClick={onCancelHandler}>
                {i18n.t('createClientModal.decline')}
              </button>
            </div>
            <div className='field-wrapper text-center keep-logged-in'></div>
          </form>
        )}
      />
    </>
  );
};

export default ClientProfileAddSubmissionForm;
