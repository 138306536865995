import { createSlice } from '@reduxjs/toolkit';

export const EowsReducer = createSlice({
    name: 'eows',
    initialState: {
        statuses: [],
    },
    reducers: {
        setStatuses: (state, { payload }) => {
            state.statuses = payload;
        },
    },
});

export const { setStatuses } = EowsReducer.actions;
export default EowsReducer.reducer;
