import { useState } from 'react';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import i18n from '../../../app/i18n';
import cms from '../../../app/api/cms';
import './AssignMeModal.scss';

const AssignMeModal = ({ assignId }) => {
  const [assignMeModalOpen, setAssignMeModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const onClickHandler = async () => {
    await cms
      .AssignToAdminWebSubmission(assignId)
      .then(function (response) {
        if (response.data.message === 'action_success') {
          setAssignMeModalOpen(false);
          window.location.reload();
        }
      })
      .catch(function (error) {
        if (error.response.data.error !== undefined) {
          setErrorMessage(i18n.t(`formErrors.${error.response.data.error}`));
        }
      });
  };

  const onCancelHandler = () => {
    setAssignMeModalOpen(false);
  };

  function assignArchiveModal() {
    return (
      <Modal
        className='delete-user-model'
        centered={true}
        isOpen={assignMeModalOpen}
        toggle={onCancelHandler}
      >
        <ModalHeader charcode='Y' toggle={() => setAssignMeModalOpen(false)}></ModalHeader>
        <ModalBody>
          <p>{errorMessage}</p>
          <h6>{i18n.t('asignMeModal.sureAssignMe')}</h6>
        </ModalBody>
        <ModalFooter className='d-flex justify-content-between'>
          <button onClick={onClickHandler} className='cancel-user-deletion'>
            {i18n.t('asignMeModal.assign')}
          </button>
          <button className='confirm-user-deletion' onClick={onCancelHandler}>
            {i18n.t('asignMeModal.decline')}
          </button>
        </ModalFooter>
      </Modal>
    );
  }

  return (
    <>
      {assignArchiveModal()}
      <button onClick={() => setAssignMeModalOpen(true)} className='assign-me-btn'>
        Priskirti man
      </button>
    </>
  );
};

export default AssignMeModal;
