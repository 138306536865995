import { useEffect, useState } from 'react';
import PageTitle from '../../../../components/pageTitle/PageTitle';
import i18n from '../../../../app/i18n';
import { useSelector, useDispatch } from 'react-redux';
import cms from '../../../../app/api/cms';
import { setFields } from '../../../../app/features/basicConfigs';
import { setDefaults } from '../../../../app/features/services';
import { setListForForm } from '../../../../app/features/clients';
import { setStatuses } from '../../../../app/features/eows';
import { Spinner } from 'reactstrap';
import '../eowCreate/AddForm.scss';
import EditForm from './EditForm';
import React from 'react';
import { useParams } from 'react-router-dom';

const EowEditPage = () => {
    const dispatch = useDispatch();
    const { rowId } = useParams();
    const basicFields = useSelector((state) => state.basicConfigs.fields);
    const defaultServices = useSelector((state) => state.services.defaults);
    const clientsForForm = useSelector((state) => state.clients.listForForm);
    const statuses = useSelector((state) => state.eows.statuses);
    const [loadedFields, setLoadedFields] = useState(false);
    const [loadedServices, setLoadedServices] = useState(false);
    const [loadedClients, setLoadedClients] = useState(false);
    const [loadedStatuses, setLoadedStatuses] = useState(false);
    const [loadedEow, setLoadedEow] = useState(false);
    const [eowData, setEowData] = useState(null);

    useEffect(() => {
        if (basicFields && basicFields.length === 0) {
            loadBasicFields();
        } else {
            setLoadedFields(true);
        }
        if (defaultServices && defaultServices.length === 0) {
            loadServices();
        } else {
            setLoadedServices(true);
        }
        if (clientsForForm && clientsForForm.length === 0) {
            loadClients();
        } else {
            setLoadedClients(true);
        }

        if (statuses && statuses.length === 0) {
            loadStatuses();
        } else {
            setLoadedStatuses(true);
        }

        loadEowData();
    }, []);

    const loadEowData = async () => {
        await cms
            .GetEowData(rowId)
            .then(function (response) {
                if (response.data.data !== undefined) {
                    setEowData(response.data.data);
                    setLoadedEow(true);
                }
            })
            .catch(function (error) {});
    };

    const loadStatuses = async () => {
        await cms
            .GetEowStatuses()
            .then(function (response) {
                if (response.data.data !== undefined) {
                    dispatch(setStatuses(response.data.data));
                    setLoadedStatuses(true);
                }
            })
            .catch(function (error) {
                console.log(error, 'error');
            });
    };

    const loadClients = async () => {
        await cms
            .GetClientsForSelect()
            .then(function (response) {
                if (response.data.data !== undefined) {
                    dispatch(setListForForm(response.data.data));
                    setLoadedClients(true);
                }
            })
            .catch(function (error) {});
    };

    const loadBasicFields = async () => {
        await cms
            .GetBasicFields()
            .then(function (response) {
                if (response.data.data !== undefined) {
                    dispatch(setFields(response.data.data));
                    setLoadedFields(true);
                }
            })
            .catch(function (error) {});
    };

    const loadServices = async () => {
        await cms
            .GetDefaultServices()
            .then(function (response) {
                if (response.data.data !== undefined) {
                    dispatch(setDefaults(response.data.data));
                    setLoadedServices(true);
                }
            })
            .catch(function (error) {});
    };

    return (
        <div className='add-form-page'>
            <>
                {loadedFields && loadedServices && loadedClients && loadedEow && loadedStatuses ? (
                    <>
                        <PageTitle>SĄMATA #{eowData.id}</PageTitle>
                        <EditForm eowId={rowId} eowData={eowData} />
                    </>
                ) : (
                    <Spinner>Loading...</Spinner>
                )}
            </>
        </div>
    );
};

export default EowEditPage;
