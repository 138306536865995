import { createSlice } from '@reduxjs/toolkit';

export const ServicesReducer = createSlice({
    name: 'services',
    initialState: {
        defaults: [],
    },
    reducers: {
        setDefaults: (state, { payload }) => {
            state.defaults = payload;
        },
    },
});

export const { setDefaults } = ServicesReducer.actions;
export default ServicesReducer.reducer;
