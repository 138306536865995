import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faEnvelope, faUser, faArrowRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import blanckUser from '../../../../images/blank-profile-picture-973460_1280.png';
import './Header.scss';

const Header = () => {
    const [showNotifications, setShowNotifications] = useState(false);
    const [showUserDropdown, setShowUserDropdown] = useState(false);
    const currentUser = useSelector((state) => state.users.currentUser);

    const handleOnBlurNotifications = (e) => {
        if (!e.currentTarget.contains(e.relatedTarget)) {
            setShowNotifications(false);
        }
    };

    const handleOnBlurUserDropdown = (e) => {
        if (!e.currentTarget.contains(e.relatedTarget)) {
            setShowUserDropdown(false);
        }
    };

    const onNotificationHandler = (el) => {};

    function notificationList() {
        return (
            <div className={`dropdown-notification ${showNotifications ? 'show' : ''}`}>
                <div className='notification-title'>
                    <h4>Pranešimai</h4>
                    <span className='notification-buble'>10</span>
                </div>
                <div>
                    <div className='notification-row'>
                        <FontAwesomeIcon icon={faEnvelope} />
                        <div>
                            <h6>Congratulation Sam</h6>
                            <span>Won the monthly best seller</span>
                        </div>
                    </div>
                    <div className='notification-row'>
                        <FontAwesomeIcon icon={faEnvelope} />
                        <div>
                            <h6>Congratulation Sam</h6>
                            <span>Won the monthly best seller. Won the monthly best seller</span>
                        </div>
                    </div>
                    <div className='notification-row'>
                        <FontAwesomeIcon icon={faEnvelope} />
                        <div>
                            <h6>Congratulation Sam</h6>
                            <span>Won the monthly best seller</span>
                        </div>
                    </div>
                </div>
                <Link to='/' className='notification-btn'>
                    Skaityti visus pranešimus
                </Link>
            </div>
        );
    }
    function userDropdown() {
        return (
            <div className={`dropdown-user ${showUserDropdown ? 'show-user' : ''}`}>
                <div className='user-dropdown-row'>
                    <FontAwesomeIcon icon={faUser} />
                    <Link to='/cms/admin/profile'>Profilis</Link>
                </div>
                <div className='user-dropdown-row'>
                    <FontAwesomeIcon icon={faArrowRightFromBracket} />
                    <Link to='/'>Atsijungti</Link>
                </div>
            </div>
        );
    }
    return (
        <div className='header-box'>
            <div className='header-content'>
                {/* <div
          className='notification'
          tabIndex='0'
          onBlur={(event) => handleOnBlurNotifications(event)}
          onClick={() => setShowNotifications(!showNotifications)}
        >
          <FontAwesomeIcon icon={faBell} />
          <span className='buble'>10</span>
          {notificationList()}
        </div> */}
                <div className='photo-side d-flex align-items-center'>
                    <h6>{currentUser.name}</h6>
                    <div className='img' tabIndex='0' onBlur={(event) => handleOnBlurUserDropdown(event)} onClick={() => setShowUserDropdown(!showUserDropdown)}>
                        <img src={blanckUser} alt='' />
                        {userDropdown()}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;
