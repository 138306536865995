import { useState } from 'react';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import i18n from '../../../app/i18n';
import CreateClientModelForm from './CreateClientModelForm';
import './CreateClientModal.scss';

const CreateClientModal = ({ submissionData }) => {
  const [createClientModalOpen, setCreateClientModalOpen] = useState(false);

  const onClickHandler = () => {};

  const onCancelHandler = () => {
    setCreateClientModalOpen(false);
  };

  function clientCreateModal() {
    return (
      <Modal
        className='delete-user-model'
        centered={true}
        isOpen={createClientModalOpen}
        toggle={onCancelHandler}
      >
        <ModalHeader charcode='Y' toggle={() => setCreateClientModalOpen(false)}>
          <h6>{i18n.t('createClientModal.clientCreation')}</h6>
        </ModalHeader>
        <ModalBody>
          <CreateClientModelForm
            submissionData={submissionData}
            onCancelHandler={onCancelHandler}
          />
        </ModalBody>
      </Modal>
    );
  }
  return (
    <>
      {clientCreateModal()}
      <button onClick={() => setCreateClientModalOpen(true)} className='client-create-btn'>
        Sukurti klientą
      </button>
    </>
  );
};

export default CreateClientModal;
