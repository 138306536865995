import './SelectField.scss';

const SelectField = (props) => {
  return (
    <>
      <div className='input-box'>
        {props.label ? <label className='input-label'>{props.label}</label> : <></>}
        <select
          className={`form-select ${
            (props.meta.error || props.meta.submitError) && props.meta.touched ? 'is-invalid' : ''
          }`}
          placeholder={props.placeholder}
          {...props.select}
        >
          {props.children}
        </select>
        {(props.meta.error || props.meta.submitError) && props.meta.touched && (
          <div className='invalid-feedback'>{props.meta.error || props.meta.submitError}</div>
        )}
      </div>
    </>
  );
};

export default SelectField;
