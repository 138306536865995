import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector, useDispatch } from 'react-redux';
import { setStatuses } from '../../../../app/features/eows';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import PageTitle from '../../../../components/pageTitle/PageTitle';
import DeleteUserModal from '../../../../components/modals/deleteUser/DeleteUserModal';
import PaginationGolbal from '../../../../components/pagination/PaginationGlobal';
import cms from '../../../../app/api/cms';
import i18n from '../../../../app/i18n';
import { FULL_ADMIN_ROUTES } from '../../../../app/routes/routeLinks';
import './EowListPage.scss';
import StatusBadge from './StatusBadge';
import { Alert } from 'reactstrap';

const EowListPage = () => {
    const dispatch = useDispatch();
    const statuses = useSelector((state) => state.eows.statuses);
    const [eows, setEows] = useState([]);
    const [totalItemsCount, setTotalItemsCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        if (statuses && statuses.length === 0) {
            loadStatuses();
        } else {
            loadRows(currentPage, '');
        }
    }, [statuses, currentPage]);

    const loadStatuses = async () => {
        await cms
            .GetEowStatuses()
            .then(function (response) {
                if (response.data.data !== undefined) {
                    dispatch(setStatuses(response.data.data));
                }
            })
            .catch(function (error) {
                console.log(error, 'error');
            });
    };

    const handleDeleteRow = async (rowId) => {
        await cms
            .DeleteEow(rowId)
            .then(function (response) {
                if (response.data.message === 'eow_deleted') {
                    loadRows(currentPage, '');
                }
            })
            .catch(function (error) {
                console.log(error, 'error');
            });
    };

    const loadRows = async (currentPageIndex, queryPart) => {
        setEows([]);

        await cms
            .GetEows(currentPageIndex, queryPart)
            .then(function (response) {
                console.log('res->', response);
                if (response.data.data !== undefined) {
                    setEows(response.data.data.rows);
                    setTotalItemsCount(response.data.data.total_count);
                }
            })
            .catch(function (error) {});
    };

    const handlePageChange = (newActivePage) => {
        setCurrentPage(newActivePage);
    };

    function StatusHtml(elStatus) {
        if (statuses && statuses.length > 0) {
            let findStatus = statuses.find((el) => el.value === elStatus);
            if (findStatus !== -1) {
                return <StatusBadge status={elStatus} text={findStatus.label} />;
            }
        }
    }

    return (
        <div className='users-page'>
            <PageTitle>{i18n.t('eowPages.title')}</PageTitle>
            <div className='table-box'>
                <div className='upper-side'>
                    <Link to={FULL_ADMIN_ROUTES.eowsAddPage}>{i18n.t('eowPages.createNew')}</Link>
                </div>
                {eows && eows.length > 0 ? (
                    <>
                        <div className='table-all'>
                            <div className='table-row main'>
                                <div style={{ width: '10%' }}>
                                    <h6>ID</h6>
                                </div>
                                <div style={{ width: '30%' }}>
                                    <h6>Klientas</h6>
                                </div>
                                <div style={{ width: '30%' }}>
                                    <h6>Statusas</h6>
                                </div>
                                <div style={{ width: '20%' }}>
                                    <h6>Suma</h6>
                                </div>
                                <div style={{ width: '10%' }}>
                                    <h6>Veiksmai</h6>
                                </div>
                            </div>
                            {eows.map((eow) => (
                                <div className='table-row' key={`${eow.id}-eows`}>
                                    <div style={{ width: '10%' }} className='mobile-style'>
                                        <h6 className='mobile-titles'>ID</h6>
                                        <p>{eow.id}</p>
                                    </div>
                                    <div style={{ width: '30%' }} className='mobile-style'>
                                        <h6 className='mobile-titles'>KLIENTAS</h6>
                                        <p>{eow.client_full_name}</p>
                                    </div>
                                    <div style={{ width: '30%' }} className='mobile-style'>
                                        <h6 className='mobile-titles'>STATUSAS</h6>
                                        <p>{StatusHtml(eow.status)}</p>
                                    </div>
                                    <div style={{ width: '20%' }} className='mobile-style'>
                                        <h6 className='mobile-titles'>SUMA</h6>
                                        <p>{eow.grand_total} &euro;</p>
                                    </div>
                                    <div style={{ width: '10%' }} className='mobile-style pt-2'>
                                        <h6 className='mobile-titles'>VEIKSMAI</h6>
                                        <p className='icons-table mobile-small-icon'>
                                            <Link to={`/cms/admin/eows/${eow.id}/edit`}>
                                                <FontAwesomeIcon className='mob-r' icon={faPenToSquare} />
                                            </Link>
                                            <DeleteUserModal rowId={eow.id} onSuccess={handleDeleteRow} textContent={`Ar tikrai norite ištrinti?`} title={`Sąmata #${eow.id} trinimas`} />
                                        </p>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className='pagination-container'>
                            <PaginationGolbal activePage={currentPage} totalItemsCount={totalItemsCount} handlePageChange={handlePageChange} itemsCountPerPage={10} />
                        </div>
                    </>
                ) : (
                    <Alert style={{ marginLeft: '20px', marginRight: '20px' }} color='primary'>
                        Sąmatų nera.
                    </Alert>
                )}
            </div>
        </div>
    );
};

export default EowListPage;
