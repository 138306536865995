import { createSlice } from '@reduxjs/toolkit';

export const InvocesReducer = createSlice({
    name: 'invoices',
    initialState: {
        statuses: [
            {
                value: 'new',
                label: 'Naujas',
            },
            {
                value: 'pending_payment',
                label: 'Laukiamas mokėjimas',
            },
            {
                value: 'payed',
                label: 'Apmokėta',
            },
            {
                value: 'declined',
                label: 'Atmesta',
            },
        ],
        types: [
            {
                value: 'simple',
                label: 'Paprasta',
            },
            {
                value: 'before_time',
                label: 'Išankstinė',
            }
        ]
    },
    // reducers: {
    //     setFields: (state, { payload }) => {
    //         state.fields = payload;
    //     },
    // },
});

//export const { setFields } = InvociesReducer.actions;
export default InvocesReducer.reducer;
