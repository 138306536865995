import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import PageTitle from '../../../components/pageTitle/PageTitle';
import cms from '../../../app/api/cms';
import StatusBadge from '../../admin/invoices/invoicesList/StatusBadge';
import { Alert } from 'reactstrap';

const InviocesTable = ({ dashboardData }) => {
  const statuses = useSelector((state) => state.invoices.statuses);

  function StatusHtml(elStatus) {
    if (statuses && statuses.length > 0) {
      let findStatus = statuses.find((el) => el.value === elStatus);
      if (findStatus !== -1) {
        return <StatusBadge status={elStatus} text={findStatus.label} />;
      }
    }
  }

  return (
    <div className='users-page dashboard'>
      <div className='table-box'>
        <PageTitle>Neapmokėtos sąskaitos</PageTitle>
        {dashboardData && dashboardData.length > 0 ? (
          <>
            <div className='table-all'>
              <div className='table-row main'>
                <div style={{ width: '10%' }}>
                  <h6>ID</h6>
                </div>
                <div style={{ width: '30%' }}>
                  <h6>Sąskaita / Klientas</h6>
                </div>
                <div style={{ width: '30%' }}>
                  <h6>Statusas</h6>
                </div>
                <div style={{ width: '20%' }}>
                  <h6>Suma</h6>
                </div>
                <div style={{ width: '10%' }}>
                  <h6>Veiksmai</h6>
                </div>
              </div>
              {dashboardData.map((row) => (
                <div className='table-row' key={`${row.id}-rows`}>
                  <div style={{ width: '10%' }} className='mobile-style'>
                    <h6 className='mobile-titles'>ID</h6>
                    <p>{row.id}</p>
                  </div>
                  <div style={{ width: '30%' }} className='mobile-style'>
                    <h6 className='mobile-titles'>SĄSKAITA / KLIENTAS</h6>
                    <p>
                      NR. {row.identifier_prefix}-{row.identifier}
                      <br />
                      {row.buyer_name}
                    </p>
                  </div>
                  <div style={{ width: '30%' }} className='mobile-style'>
                    <h6 className='mobile-titles'>STATUSAS</h6>
                    <p>{StatusHtml(row.status)}</p>
                  </div>
                  <div style={{ width: '20%' }} className='mobile-style'>
                    <h6 className='mobile-titles'>SUMA</h6>
                    <p>{row.grand_total} &euro;</p>
                  </div>
                  <div style={{ width: '10%' }} className='mobile-style'>
                    <h6 className='mobile-titles'>VEIKSMAI</h6>
                    <p className='icons-table dash-icon'>
                      <Link to={`/cms/admin/invoices/${row.id}/edit`}>
                        <FontAwesomeIcon icon={faPenToSquare} />
                      </Link>
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </>
        ) : (
          <Alert style={{ marginLeft: '20px', marginRight: '20px' }} color='primary'>
            Sąskaitų nėra.
          </Alert>
        )}
      </div>
    </div>
  );
};

export default InviocesTable;
