import { Badge } from 'reactstrap';
const TypeBadge = ({ type, text }) => {
    let statusClass = '';

    if (type === 'simple') {
        statusClass = 'secondary';
    } else if (type === 'before_time') {
        statusClass = 'info';
    } else {
        statusClass = 'secondary';
    }

    return <Badge color={statusClass}>{text}</Badge>;
};

export default TypeBadge;