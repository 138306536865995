import React, { useState } from 'react';
import { Alert } from 'reactstrap';
import { Form, Field } from 'react-final-form';
import { useNavigate } from 'react-router-dom';
import { InputFieldWithIcon } from '../../../components/form';
import i18n from '../../../app/i18n';
import { FULL_ADMIN_ROUTES } from '../../../app/routes/routeLinks';
import auth from '../../../app/api/auth';
import validator from '../../../app/utils/validations';
import './LoginPage.scss';

const LoginForm = () => {
  const [formFieldValues] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  let goToDashboardPage = useNavigate();

  const validate = (values) => {
    const errors = {};

    if (!values.email) {
      errors.email = i18n.t('formErrors.requiredField');
    } else if (validator.isEmail(values.email) === false) {
      errors.email = i18n.t('formErrors.entarValidEmail');
    }

    if (!values.password) {
      errors.password = i18n.t('formErrors.requiredField');
    }

    return errors;
  };

  const onSubmit = async (values) => {
    let formData = new FormData();
    setErrorMessage('');
    formData.append('email', values.email);
    formData.append('password', values.password);

    let isErrors = false;
    let formErrors = {};

    await auth
      .Login(formData)
      .then(function (response) {
        if (response.data.access_token !== undefined) {
          localStorage.setItem('userToken', response.data.access_token);
          goToDashboardPage(FULL_ADMIN_ROUTES.dashboardPage);
        }
      })
      .catch(function (error) {
        if (error.response.data.error !== undefined) {
          setErrorMessage(i18n.t(`formErrors.${error.response.data.error}`));
        }
        if (error.response.data !== undefined) {
          for (const [key, value] of Object.entries(error.response.data)) {
            formErrors[key] = i18n.t(`formError.${value}`);
          }
          isErrors = true;
        }
      });
    if (isErrors) {
      return formErrors;
    }
  };

  const formFields = [
    {
      field: (
        <Field name='email'>
          {({ input, meta }) => (
            <InputFieldWithIcon
              placeholder={i18n.t('loginPage.emailPlaceHolder')}
              input={input}
              meta={meta}
            ></InputFieldWithIcon>
          )}
        </Field>
      ),
    },
    {
      field: (
        <Field name='password'>
          {({ input, meta }) => (
            <InputFieldWithIcon
              type='password'
              placeholder={i18n.t('loginPage.passwordPlaceHolder')}
              input={input}
              meta={meta}
            ></InputFieldWithIcon>
          )}
        </Field>
      ),
    },
  ];

  return (
    <>
      {errorMessage && errorMessage !== '' ? <Alert color='danger'>{errorMessage}</Alert> : ''}
      <Form
        onSubmit={onSubmit}
        initialValues={formFieldValues}
        validate={validate}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} noValidate>
            {formFields.map((item, key) => (
              <React.Fragment key={`${key}-fields`}>{item.field}</React.Fragment>
            ))}
            <div className='d-sm-flex justify-content-between'>
              <button className='login-btn' type='submit'>
                {i18n.t('loginPage.formSubmitButtonText')}
              </button>
            </div>
            <div className='field-wrapper text-center keep-logged-in'></div>
          </form>
        )}
      />
    </>
  );
};

export default LoginForm;
