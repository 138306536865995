import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Alert } from 'reactstrap';
import { Form, Field } from 'react-final-form';
import { InputFieldWithIcon } from '../../../components/form';
import i18n from '../../../app/i18n';
import cms from '../../../app/api/cms';

const EditDefaultForm = ({ editData }) => {
  const [formFieldValues] = useState(editData);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const validate = (values) => {
    const errors = {};

    return errors;
  };

  const onSubmit = async (values) => {
    let formData = new FormData();
    setErrorMessage('');

    formData.append('device_name', values.device_name !== undefined ? values.device_name : '');
    formData.append('device_population', values.device_population !== undefined ? values.device_population : '');
    formData.append('device_performance', values.device_performance !== undefined ? values.device_performance : '');
    formData.append('device_cover', values.device_cover !== undefined ? values.device_cover : '');
    formData.append('device_efficiency', values.device_efficiency !== undefined ? values.device_efficiency : '');

    formData.append('company_bank_info', values.company_bank_info !== undefined ? values.company_bank_info : '');
    formData.append('company_code', values.company_code !== undefined ? values.company_code : '');
    formData.append('company_director', values.company_director !== undefined ? values.company_director : '');
    formData.append('company_email', values.company_email !== undefined ? values.company_email : '');
    formData.append('company_iban', values.company_iban !== undefined ? values.company_iban : '');
    formData.append('company_name', values.company_name !== undefined ? values.company_name : '');
    formData.append('company_phone', values.company_phone !== undefined ? values.company_phone : '');
    formData.append('company_vat_code', values.company_vat_code !== undefined ? values.company_vat_code : '');
    formData.append('company_web_site', values.company_web_site !== undefined ? values.company_web_site : '');
    formData.append('invoice_identify_prefix', values.invoice_identify_prefix !== undefined ? values.invoice_identify_prefix : '');

    let isErrors = false;
    let formErrors = {};

    await cms
      .UpdateBaseConfigs(formData)
      .then(function (response) {
        setSuccessMessage('Issaugota!');
      })
      .catch(function (error) {
        if (error.response.data.error !== undefined) {
          setErrorMessage(i18n.t(`formErrors.${error.response.data.error}`));
        }
        if (error.response.data !== undefined) {
          for (const [key, value] of Object.entries(error.response.data)) {
            formErrors[key] = i18n.t(`formError.${value}`);
          }
          isErrors = true;
        }
      });
    if (isErrors) {
      return formErrors;
    }
  };

  const formFields = [
    {
      field: <Field name='company_bank_info'>{({ input, meta }) => <InputFieldWithIcon placeholder='Banko info' input={input} meta={meta} label='Banko info'></InputFieldWithIcon>}</Field>,
    },
    {
      field: <Field name='company_code'>{({ input, meta }) => <InputFieldWithIcon placeholder='Imones kodas' input={input} meta={meta} label='Imones kodas'></InputFieldWithIcon>}</Field>,
    },
    {
      field: (
        <Field name='company_director'>
          {({ input, meta }) => <InputFieldWithIcon placeholder='Imones atstovas saskaituose' input={input} meta={meta} label='Imones atstovas saskaituose'></InputFieldWithIcon>}
        </Field>
      ),
    },
    {
      field: <Field name='company_email'>{({ input, meta }) => <InputFieldWithIcon placeholder='Imones el. pastas' input={input} meta={meta} label='Imones el. pastas'></InputFieldWithIcon>}</Field>,
    },
    {
      field: <Field name='company_iban'>{({ input, meta }) => <InputFieldWithIcon placeholder='Bank. sask.' input={input} meta={meta} label='Bank. sask.'></InputFieldWithIcon>}</Field>,
    },
    {
      field: <Field name='company_name'>{({ input, meta }) => <InputFieldWithIcon placeholder='Imones pav.' input={input} meta={meta} label='Imones pav.'></InputFieldWithIcon>}</Field>,
    },
    {
      field: <Field name='company_phone'>{({ input, meta }) => <InputFieldWithIcon placeholder='Imones tel.' input={input} meta={meta} label='Imones tel.'></InputFieldWithIcon>}</Field>,
    },
    {
      field: <Field name='company_vat_code'>{({ input, meta }) => <InputFieldWithIcon placeholder='Imones PVM kodas' input={input} meta={meta} label='Imones PVM kodas'></InputFieldWithIcon>}</Field>,
    },
    {
      field: <Field name='company_web_site'>{({ input, meta }) => <InputFieldWithIcon placeholder='Imones web url' input={input} meta={meta} label='Imones web url'></InputFieldWithIcon>}</Field>,
    },
    {
      field: (
        <Field name='invoice_identify_prefix'>
          {({ input, meta }) => <InputFieldWithIcon placeholder='Saskaitos prefixas' input={input} meta={meta} label='Saskaitos prefixas'></InputFieldWithIcon>}
        </Field>
      ),
    },
  ];

  const deviceFieldRows = [
    {
      field: <Field name='device_name'>{({ input, meta }) => <InputFieldWithIcon placeholder='Modelis' input={input} meta={meta} label='Modelis'></InputFieldWithIcon>}</Field>,
    },
    {
      field: (
        <Field name='device_population'>{({ input, meta }) => <InputFieldWithIcon placeholder='Gyventojų skaičius' input={input} meta={meta} label='Gyventojų skaičius'></InputFieldWithIcon>}</Field>
      ),
    },
    {
      field: <Field name='device_performance'>{({ input, meta }) => <InputFieldWithIcon placeholder='Našumas' input={input} meta={meta} label='Našumas'></InputFieldWithIcon>}</Field>,
    },
    {
      field: <Field name='device_cover'>{({ input, meta }) => <InputFieldWithIcon placeholder='Dangtis' input={input} meta={meta} label='Dangtis'></InputFieldWithIcon>}</Field>,
    },
    {
      field: (
        <Field name='device_efficiency'>
          {({ input, meta }) => <InputFieldWithIcon placeholder='Išvalymo efektyvumas' input={input} meta={meta} label='Išvalymo efektyvumas'></InputFieldWithIcon>}
        </Field>
      ),
    },
  ];

  return (
    <>
      {successMessage && successMessage !== '' ? <Alert color='success'>{successMessage}</Alert> : ''}
      {errorMessage && errorMessage !== '' ? <Alert color='danger'>{errorMessage}</Alert> : ''}
      <Form
        onSubmit={onSubmit}
        initialValues={formFieldValues}
        validate={validate}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} className='user-profile-update-form' noValidate>
            <div className='row'>
              {formFields.map((item, key) => (
                <div className='col-lg-6' key={`${key}-fields`}>
                  {item.field}
                </div>
              ))}
            </div>
            <div className='row'>
              <div className='col-lg-12'>
                <h3 className='form-title'>Nuotekų valymo įrenginio nustatymai</h3>
                <hr />
              </div>
            </div>
            <div className='row'>
              {deviceFieldRows.map((item, key) => (
                <div className='col-lg-6' key={`${key}-device-fields`}>
                  {item.field}
                </div>
              ))}
            </div>
            <div className='d-flex justify-content-between mob-table-btn'>
              <button className='edit-user-btn' type='submit'>
                {i18n.t('userProfileEditForm.updateBtn')}
              </button>
            </div>
            <div className='field-wrapper text-center keep-logged-in'></div>
          </form>
        )}
      />
    </>
  );
};

export default EditDefaultForm;
