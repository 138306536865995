import { createSlice } from '@reduxjs/toolkit';

export const UserReducer = createSlice({
  name: 'users',
  initialState: {
    currentUser: null,
  },
  reducers: {
    setCurrentUserData: (state, { payload }) => {
      state.currentUser = payload;
    },
  },
});

export const { setCurrentUserData } = UserReducer.actions;
export default UserReducer.reducer;
