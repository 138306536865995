import { useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import i18n from '../../../app/i18n';
import EditClientLabelForm from './EditClientLabelForm';
import '../addClientLabel/addClientLabelModal.scss';

const EditClientLabelModal = ({ onSuccessHandler, labelId, labelObj }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const onCancelHandler = () => {
    setModalOpen(false);
  };

  const afterCreate = () => {
    setModalOpen(false);
    onSuccessHandler();
  };

  function ModalHtml() {
    return (
      <Modal
        className='delete-user-model'
        centered={true}
        isOpen={modalOpen}
        toggle={onCancelHandler}
      >
        <ModalHeader charcode='Y' toggle={() => setModalOpen(false)}>
          <h6>Redaguoti žymą</h6>
        </ModalHeader>
        <ModalBody>
          <EditClientLabelForm
            labelId={labelId}
            labelObj={labelObj}
            onCancelHandler={onCancelHandler}
            onSuccessHandler={afterCreate}
          />
        </ModalBody>
      </Modal>
    );
  }
  return (
    <>
      {ModalHtml()}
      <button onClick={() => setModalOpen(true)} className='icon-button mob-r'>
        <FontAwesomeIcon icon={faPenToSquare} />
      </button>
    </>
  );
};

export default EditClientLabelModal;
