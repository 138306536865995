import { BrowserRouter, Route, Routes } from 'react-router-dom';
import authRoutes from './routes/authRoutes';
import adminRoutes from './routes/adminRoutes';
import { LoginPage } from '../pages/auth';
import { DashboardPage } from '../pages/admin';
import AuthLayout from '../components/layout/auth/AuthLayout';
import CmsLayout from '../components/layout/cms/CmsLayout';

function App() {
  const authRouteComponents = authRoutes.map(({ path, component }, key) => (
    <Route path={path} element={component} key={`${key}-auth-route`} />
  ));

  const adminRouteComponents = adminRoutes.map(({ path, component }, key) => (
    <Route path={path} element={component} key={`${key}-auth-route`} />
  ));

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<LoginPage />} />
          <Route path='auth/*' element={<AuthLayout />}>
            <Route index element={<LoginPage />} />
            {authRouteComponents}
          </Route>
          <Route path='cms/*' element={<CmsLayout />}>
            <Route index element={<DashboardPage />} />
            {adminRouteComponents}
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
