import logo from '../../../images/logo.png';
import LoginForm from './LoginForm';

const LoginPage = () => {
  return (
    <>
      <div className='container'>
        <div className='row justify-content-center align-items-center height100'>
          <div className='col-xl-4 col-lg-6 col-md-8 col-sm-12 col-12'>
            <div className='for-bg'>
              <div className='login-container'>
                <div className='logo'>
                  <img src={logo} alt='logo' />
                </div>
                <h4>Prisijungti</h4>
                <LoginForm />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginPage;
