import { useEffect, useState } from 'react';
import PageTitle from '../../../../components/pageTitle/PageTitle';
import i18n from '../../../../app/i18n';
import { useSelector, useDispatch } from 'react-redux';
import cms from '../../../../app/api/cms';
import { setFields } from '../../../../app/features/basicConfigs';
import { setDefaults } from '../../../../app/features/services';
import { setListForForm } from '../../../../app/features/clients';
import { Spinner } from 'reactstrap';
import EditForm from './EditForm';
import './AddForm.scss';
import { useParams } from 'react-router-dom';

const InvoiceEditPage = () => {
    const dispatch = useDispatch();
    const { rowID } = useParams();
    const clientsForForm = useSelector((state) => state.clients.listForForm);
    const [loadedClients, setLoadedClients] = useState(false);
    const [loadedInvoice, setLoadedInvoice] = useState(false);
    const [invoice, setInvoice] = useState(null);

    useEffect(() => {
        if (clientsForForm && clientsForForm.length === 0) {
            loadClients();
        } else {
            setLoadedClients(true);
        }
        loadInvoice();
    }, [clientsForForm]);

    const loadClients = async () => {
        await cms
            .GetClientsForSelect()
            .then(function (response) {
                if (response.data.data !== undefined) {
                    dispatch(setListForForm(response.data.data));
                    setLoadedClients(true);
                }
            })
            .catch(function (error) {
                console.log(error, 'error');
            });
    };

    const loadInvoice = async () => {
        await cms
            .GetInvoice(rowID)
            .then(function (response) {
                if (response.data.data !== undefined) {
                    setInvoice(response.data.data);
                    setLoadedInvoice(true);
                }
            })
            .catch(function (error) {
                console.log(error, 'error');
            });
    };

    return (
        <div className='add-form-page'>
            <>
                {loadedClients && loadedInvoice ? (
                    <>
                        <PageTitle>Sąskaita #{invoice.id}</PageTitle>
                        <EditForm invoice={invoice} />
                    </>
                ) : (
                    <Spinner>Loading...</Spinner>
                )}
            </>
        </div>
    );
};

export default InvoiceEditPage;
