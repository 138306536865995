import React, { useState, useEffect } from 'react';
import { Form, Field } from 'react-final-form';
import { SelectField, InputFieldWithIcon } from '../../../../components/form';
import i18n from '../../../../app/i18n';

const InvoiceFilter = ({ currentFilterFields, makeSearch, invoiceStatuses, invoceTypes }) => {
  const [formFieldValues] = useState(currentFilterFields);
  const statusesForDrop = [{value: '', label: 'Visi statusai'}, ...invoiceStatuses]
  const typesForDrop = [{value: '', label: 'Visi tipai'}, ...invoceTypes]

  console.log('invoiceStatuses->', statusesForDrop);
  console.log('typesForDrop->', typesForDrop);
  

  const validate = (values) => {
    const errors = {};

    return errors;
  };

  const onSubmit = async (values) => {
    console.log('ss--->', values);
    makeSearch(values);
  };

  const formFields = [
    {
      field: (
        <Field name='clientFullName'>{({ input, meta }) => <InputFieldWithIcon placeholder={i18n.t('invoiceFilter.clientFullName')} input={input} meta={meta}></InputFieldWithIcon>}</Field>
      ),
    },
    {
      field: (
        <Field name='invoiceNumber'>{({ input, meta }) => <InputFieldWithIcon placeholder={i18n.t('invoiceFilter.invoiceNumber')} input={input} meta={meta}></InputFieldWithIcon>}</Field>
      ),
    },
    {
      field: (
          <Field name='status'>
              {({ input, meta }) => (
                  <SelectField select={input} meta={meta}>
                      {statusesForDrop.map((val, index) => {
                        return (<option value={val.value} key={`status-labels-in-filter-${index}`}>{val.label}</option>)
                      })}
                  </SelectField>
              )}
          </Field>
      ),
    },
    {
      field: (
          <Field name='invoice_type'>
              {({ input, meta }) => (
                  <SelectField select={input} meta={meta}>
                      {typesForDrop.map((val, index) => {
                        return (<option value={val.value} key={`type-labels-in-filter-${index}`}>{val.label}</option>)
                      })}
                  </SelectField>
              )}
          </Field>
      ),
    },
  ];

  return (
    <>
      <Form
        onSubmit={onSubmit}
        initialValues={formFieldValues}
        validate={validate}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} className='select-label-form full-width' noValidate>
            <div className='row'>
              
                {formFields.map((item, key) => (
                  <div className='col-12 col-sm-12 col-md-4 col-lg-3'><div key={`${key}-fields`}>{item.field}</div></div>
                ))}
              
              <div className='col-12 col-sm-12 col-md-12 col-lg-3'>
                <button className='btn btn-seen-main' type='submit'>
                  Filtruoti
                </button>
              </div>
            </div>
          </form>
        )}
      />
    </>
  );
};

export default InvoiceFilter;
