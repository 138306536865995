import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Alert } from 'reactstrap';
import { Form, Field } from 'react-final-form';
import { useNavigate } from 'react-router-dom';
import { InputFieldWithIcon } from '../../../components/form';
import i18n from '../../../app/i18n';
import validator from '../../../app/utils/validations';

const AddClientForm = () => {
  const [formFieldValues] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  const validate = (values) => {
    const errors = {};

    if (!values.email) {
      errors.email = i18n.t('formErrors.requiredField');
    } else if (validator.isEmail(values.email) === false) {
      errors.email = i18n.t('formErrors.entarValidEmail');
    }

    if (!values.password) {
      errors.password = i18n.t('formErrors.requiredField');
    }

    return errors;
  };

  const onSubmit = async (values) => {
    let formData = new FormData();
    setErrorMessage('');
    formData.append('email', values.email);
    formData.append('password', values.password);

    let isErrors = false;
    let formErrors = {};

    // await auth
    //   .Login(formData)
    //   .then(function (response) {
    //     if (response.data.access_token !== undefined) {
    //       localStorage.setItem('userToken', response.data.access_token);
    //       goToDashboardPage(FULL_ADMIN_ROUTES.dashboardPage);
    //     }
    //   })
    //   .catch(function (error) {
    //     if (error.response.data.error !== undefined) {
    //       setErrorMessage(i18n.t(`formErrors.${error.response.data.error}`));
    //     }
    //     if (error.response.data !== undefined) {
    //       for (const [key, value] of Object.entries(error.response.data)) {
    //         formErrors[key] = i18n.t(`formError.${value}`);
    //       }
    //       isErrors = true;
    //     }
    //   });
    if (isErrors) {
      return formErrors;
    }
  };

  const formFields = [
    {
      field: (
        <Field name='name'>
          {({ input, meta }) => (
            <InputFieldWithIcon
              placeholder={i18n.t('userProfileEditForm.name')}
              input={input}
              meta={meta}
              label={i18n.t('userProfileEditForm.name')}
            ></InputFieldWithIcon>
          )}
        </Field>
      ),
    },
    {
      field: (
        <Field name='surname'>
          {({ input, meta }) => (
            <InputFieldWithIcon
              type='password'
              placeholder={i18n.t('userProfileEditForm.surname')}
              input={input}
              meta={meta}
              label={i18n.t('userProfileEditForm.surname')}
            ></InputFieldWithIcon>
          )}
        </Field>
      ),
    },
    {
      field: (
        <Field name='email'>
          {({ input, meta }) => (
            <InputFieldWithIcon
              type='password'
              placeholder={i18n.t('userProfileEditForm.email')}
              input={input}
              meta={meta}
              label={i18n.t('userProfileEditForm.email')}
            ></InputFieldWithIcon>
          )}
        </Field>
      ),
    },
    {
      field: (
        <Field name='phone'>
          {({ input, meta }) => (
            <InputFieldWithIcon
              type='password'
              placeholder={i18n.t('userProfileEditForm.phone')}
              input={input}
              meta={meta}
              label={i18n.t('userProfileEditForm.phone')}
            ></InputFieldWithIcon>
          )}
        </Field>
      ),
    },
    {
      field: (
        <Field name='settlement'>
          {({ input, meta }) => (
            <InputFieldWithIcon
              type='password'
              placeholder={i18n.t('userProfileEditForm.settlement')}
              input={input}
              meta={meta}
              label={i18n.t('userProfileEditForm.settlement')}
            ></InputFieldWithIcon>
          )}
        </Field>
      ),
    },
    {
      field: (
        <Field name='street'>
          {({ input, meta }) => (
            <InputFieldWithIcon
              type='password'
              placeholder={i18n.t('userProfileEditForm.street')}
              input={input}
              meta={meta}
              label={i18n.t('userProfileEditForm.street')}
            ></InputFieldWithIcon>
          )}
        </Field>
      ),
    },
    {
      field: (
        <Field name='phone'>
          {({ input, meta }) => (
            <InputFieldWithIcon
              type='password'
              placeholder={i18n.t('userProfileEditForm.propertyNumber')}
              input={input}
              meta={meta}
              label={i18n.t('userProfileEditForm.propertyNumber')}
            ></InputFieldWithIcon>
          )}
        </Field>
      ),
    },
  ];

  return (
    <>
      {errorMessage && errorMessage !== '' ? <Alert color='danger'>{errorMessage}</Alert> : ''}
      <Form
        onSubmit={onSubmit}
        initialValues={formFieldValues}
        validate={validate}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} className='user-profile-update-form' noValidate>
            <div className='row'>
              {formFields.map((item, key) => (
                <div className='col-lg-6' key={`${key}-fields`}>
                  {item.field}
                </div>
              ))}
            </div>
            <div className='d-sm-flex justify-content-between mt-2'>
              <button className='add-client-btn' type='submit'>
                {i18n.t('userProfileEditForm.updateBtn')}
              </button>
              <Link to='/cms/admin/clients' className='back-button' type='submit'>
                {i18n.t('back.backButton')}
              </Link>
            </div>
            <div className='field-wrapper text-center keep-logged-in'></div>
          </form>
        )}
      />
    </>
  );
};

export default AddClientForm;
