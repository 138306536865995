import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Alert } from 'reactstrap';
import { Form, Field } from 'react-final-form';
import { useNavigate } from 'react-router-dom';
import { InputFieldWithIcon, SearchableSelect, CheckBoxField } from '../../../components/form';
import i18n from '../../../app/i18n';
import validator from '../../../app/utils/validations';
import cms from '../../../app/api/cms';
import { useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';

const CreateClientModelForm = ({ onCancelHandler, client }) => {
  const labels = useSelector((state) => state.clients.labels);
  const [formFieldValues, setFormFieldValues] = useState(client);
  const [errorMessage, setErrorMessage] = useState('');
  const [labelDate, setLabelDate] = useState(null);
  const [issueDate, setIssueDate] = useState(client.completion_date === null ? null : new Date(client.completion_date));

  useEffect(() => {
    if (client.client_label_id !== null) {
      let newClient = {
        ...client,
        client_label_id: { value: client.client_label.id, label: client.client_label.title },
      };
      setLabelDate(new Date(client.last_label_change.created_at));
      setFormFieldValues(newClient);
    }
  }, []);

  const validate = (values) => {
    const errors = {};

    if (!values.name) {
      errors.name = i18n.t('formErrors.requiredField');
    }

    // if (!values.surname) {
    //   errors.surname = i18n.t('formErrors.requiredField');
    // }

    // if (!values.phone) {
    //   errors.phone = i18n.t('formErrors.requiredField');
    // }

    // if (!values.email) {
    //   errors.email = i18n.t('formErrors.requiredField');
    // } else if (validator.isEmail(values.email) === false) {
    //   errors.email = i18n.t('formErrors.entarValidEmail');
    // }

    // if (validator.isEmail(values.email) === false)
    //   errors.email = i18n.t('formErrors.entarValidEmail');

    // if (!values.settlement) {
    //   errors.settlement = i18n.t('formErrors.requiredField');
    // }

    // if (!values.street) {
    //   errors.street = i18n.t('formErrors.requiredField');
    // }

    // if (!values.property_number) {
    //   errors.property_number = i18n.t('formErrors.requiredField');
    // }

    return errors;
  };

  const onSubmit = async (values) => {
    let formData = new FormData();
    setErrorMessage('');
    formData.append('name', values.name);
    formData.append('surname', values.surname !== undefined && values.surname !== null ? values.surname : '');
    formData.append('phone', values.phone !== undefined && values.phone !== null ? values.phone : '');
    formData.append('email', values.email !== undefined && values.email !== null ? values.email : '');
    formData.append('settlement', values.settlement !== undefined && values.settlement !== null ? values.settlement : '');
    formData.append('street', values.street !== undefined && values.street !== null ? values.street : '');
    formData.append('property_number', values.property_number !== undefined && values.property_number !== null ? values.property_number : '');

    formData.append('device', values.device !== undefined && values.device !== null ? values.device : '');
    formData.append('additional_device', values.additional_device !== undefined && values.additional_device !== null ? values.additional_device : '');
    formData.append('comment', values.comment !== undefined && values.comment !== null ? values.comment : '');
    formData.append('device_price', values.device_price !== undefined && values.device_price !== null ? values.device_price : '');
    formData.append('advance_payment', values.advance_payment !== undefined && values.advance_payment !== null ? values.advance_payment : '');
    formData.append('pipeline_price', values.pipeline_price !== undefined && values.pipeline_price !== null ? values.pipeline_price : '');
    formData.append('grand_total_price', values.grand_total_price !== undefined && values.grand_total_price !== null ? values.grand_total_price : '');
    formData.append('company_name', values.company_name !== undefined && values.company_name !== null ? values.company_name : '');
    formData.append('company_vat', values.company_vat !== undefined && values.company_vat !== null ? values.company_vat : '');
    formData.append('company_code', values.company_code !== undefined && values.company_code !== null ? values.company_code : '');
    formData.append('is_company', values.is_company && values.is_company === 1 ? 1 : 0);

    const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    formData.append('completion_date', issueDate ? new Date(issueDate).toLocaleDateString('lt-LT', options) : '');

    formData.append('client_label_create_at', labelDate ? new Date(labelDate).toLocaleDateString('lt-LT', options) : '');

    if (values.client_label_id !== null && values.client_label_id !== undefined) {
      if (values.client_label_id.value !== undefined) {
        formData.append('client_label_id', values.client_label_id.value);
      } else {
        formData.append('client_label_id', '');
      }
    } else {
      formData.append('client_label_id', '');
    }

    let isErrors = false;
    let formErrors = {};

    await cms
      .UpdateClient(client.id, formData)
      .then(function (response) {
        if (response.data.data !== undefined) {
          onCancelHandler();
          window.location.reload();
        }
      })
      .catch(function (error) {
        if (error.response.data.error !== undefined) {
          setErrorMessage(i18n.t(`formErrors.${error.response.data.error}`));
        }
        if (error.response.data !== undefined) {
          for (const [key, value] of Object.entries(error.response.data)) {
            formErrors[key] = i18n.t(`formError.${value}`);
          }
          isErrors = true;
        }
      });
    if (isErrors) {
      return formErrors;
    }
  };

  const formFields = [
    {
      field: (
        <Field name='client_label_id'>{({ input, meta }) => <SearchableSelect placeholder='Žyma' options={[{ value: '', label: '-' }, ...labels]} input={input} meta={meta} label='Žyma' />}</Field>
      ),
    },
    {
      field: (
        <>
          {' '}
          <label className='input-label ps-0 pe-0'>Žymos data</label>
          <DatePicker dateFormat='yyyy-MM-dd' selected={labelDate} onChange={(date) => setLabelDate(date)} />
        </>
      ),
    },
    {
      field: (
        <Field name={'is_company'} type='checkbox' format={(v) => v === 1} parse={(v) => (v ? 1 : 0)}>
          {({ input, meta }) => (
            <>
              <p style={{ color: '#636363', fontSize: '14px', marginBottom: '8px' }}>Pasirinkite ar tai įmonė?</p>
              <CheckBoxField input={input} meta={meta} label={i18n.t('createMyClientForm.isCompany')}></CheckBoxField>
            </>
          )}
        </Field>
      ),
    },
    {
      field: (
        <Field name='name'>
          {({ input, meta }) => <InputFieldWithIcon placeholder={i18n.t('userProfileEditForm.name')} input={input} meta={meta} label={i18n.t('userProfileEditForm.name')}></InputFieldWithIcon>}
        </Field>
      ),
    },
    {
      field: (
        <Field name='surname'>
          {({ input, meta }) => <InputFieldWithIcon placeholder={i18n.t('userProfileEditForm.surname')} input={input} meta={meta} label={i18n.t('userProfileEditForm.surname')}></InputFieldWithIcon>}
        </Field>
      ),
    },
    {
      field: (
        <Field name='email'>
          {({ input, meta }) => <InputFieldWithIcon placeholder={i18n.t('userProfileEditForm.email')} input={input} meta={meta} label={i18n.t('userProfileEditForm.email')}></InputFieldWithIcon>}
        </Field>
      ),
    },
    {
      field: (
        <Field name='phone'>
          {({ input, meta }) => <InputFieldWithIcon placeholder={i18n.t('userProfileEditForm.phone')} input={input} meta={meta} label={i18n.t('userProfileEditForm.phone')}></InputFieldWithIcon>}
        </Field>
      ),
    },
    {
      field: (
        <Field name='settlement'>
          {({ input, meta }) => (
            <InputFieldWithIcon placeholder={i18n.t('userProfileEditForm.settlement')} input={input} meta={meta} label={i18n.t('userProfileEditForm.settlement')}></InputFieldWithIcon>
          )}
        </Field>
      ),
    },
    {
      field: (
        <Field name='street'>
          {({ input, meta }) => <InputFieldWithIcon placeholder={i18n.t('userProfileEditForm.street')} input={input} meta={meta} label={i18n.t('userProfileEditForm.street')}></InputFieldWithIcon>}
        </Field>
      ),
    },
    {
      field: (
        <Field name='property_number'>
          {({ input, meta }) => (
            <InputFieldWithIcon placeholder={i18n.t('userProfileEditForm.propertyNumber')} input={input} meta={meta} label={i18n.t('userProfileEditForm.propertyNumber')}></InputFieldWithIcon>
          )}
        </Field>
      ),
    },
    {
      field: (
        <Field name='device'>
          {({ input, meta }) => <InputFieldWithIcon placeholder={i18n.t('userProfileEditForm.device')} input={input} meta={meta} label={i18n.t('userProfileEditForm.device')}></InputFieldWithIcon>}
        </Field>
      ),
    },
    {
      field: (
        <Field name='additional_device'>
          {({ input, meta }) => (
            <InputFieldWithIcon placeholder={i18n.t('userProfileEditForm.additionalDevice')} input={input} meta={meta} label={i18n.t('userProfileEditForm.additionalDevice')}></InputFieldWithIcon>
          )}
        </Field>
      ),
    },
    {
      field: (
        <Field name='comment'>
          {({ input, meta }) => <InputFieldWithIcon placeholder={i18n.t('userProfileEditForm.comment')} input={input} meta={meta} label={i18n.t('userProfileEditForm.comment')}></InputFieldWithIcon>}
        </Field>
      ),
    },
    {
      field: (
        <Field name='device_price'>
          {({ input, meta }) => (
            <InputFieldWithIcon placeholder={i18n.t('userProfileEditForm.devicePrice')} input={input} meta={meta} label={i18n.t('userProfileEditForm.devicePrice')}></InputFieldWithIcon>
          )}
        </Field>
      ),
    },
    {
      field: (
        <Field name='advance_payment'>
          {({ input, meta }) => (
            <InputFieldWithIcon placeholder={i18n.t('userProfileEditForm.advancePayment')} input={input} meta={meta} label={i18n.t('userProfileEditForm.advancePayment')}></InputFieldWithIcon>
          )}
        </Field>
      ),
    },
    {
      field: (
        <Field name='pipeline_price'>
          {({ input, meta }) => (
            <InputFieldWithIcon placeholder={i18n.t('userProfileEditForm.pipelinePrice')} input={input} meta={meta} label={i18n.t('userProfileEditForm.pipelinePrice')}></InputFieldWithIcon>
          )}
        </Field>
      ),
    },
    {
      field: (
        <Field name='grand_total_price'>
          {({ input, meta }) => (
            <InputFieldWithIcon placeholder={i18n.t('userProfileEditForm.grandTotalPrice')} input={input} meta={meta} label={i18n.t('userProfileEditForm.grandTotalPrice')}></InputFieldWithIcon>
          )}
        </Field>
      ),
    },
    {
      field: (
        <Field name='company_name'>
          {({ input, meta }) => (
            <InputFieldWithIcon placeholder={i18n.t('createMyClientForm.companyName')} input={input} meta={meta} label={i18n.t('createMyClientForm.companyName')}></InputFieldWithIcon>
          )}
        </Field>
      ),
    },
    {
      field: (
        <Field name='company_vat'>
          {({ input, meta }) => (
            <InputFieldWithIcon placeholder={i18n.t('createMyClientForm.companyVat')} input={input} meta={meta} label={i18n.t('createMyClientForm.companyVat')}></InputFieldWithIcon>
          )}
        </Field>
      ),
    },
    {
      field: (
        <Field name='company_code'>
          {({ input, meta }) => (
            <InputFieldWithIcon placeholder={i18n.t('createMyClientForm.companyCode')} input={input} meta={meta} label={i18n.t('createMyClientForm.companyCode')}></InputFieldWithIcon>
          )}
        </Field>
      ),
    },
  ];

  return (
    <>
      {errorMessage && errorMessage !== '' ? <Alert color='danger'>{errorMessage}</Alert> : ''}
      <Form
        onSubmit={onSubmit}
        initialValues={formFieldValues}
        validate={validate}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} className='user-profile-update-form' noValidate>
            <div className='row'>
              {formFields.map((item, key) => (
                <div className='col-lg-4' key={`${key}-fields`}>
                  {item.field}
                </div>
              ))}
              <div className='col-lg-4'>
                <label className='input-label ps-0 pe-0'>Atlikimo data</label>
                <DatePicker dateFormat='yyyy-MM-dd' selected={issueDate} onChange={(date) => setIssueDate(date)} className='mb-3' />
              </div>
            </div>
            <div className='d-flex justify-content-between'>
              <button className='add-client-btn cancel-user-deletion' type='submit'>
                {i18n.t('userProfileEditForm.updateBtn')}
              </button>
              <button className='confirm-user-deletion' type='button' onClick={onCancelHandler}>
                {i18n.t('createClientModal.decline')}
              </button>
            </div>
            <div className='field-wrapper text-center keep-logged-in'></div>
          </form>
        )}
      />
    </>
  );
};

export default CreateClientModelForm;
