import { ADMIN_ROUTES } from './routeLinks';
import {
  DashboardPage,
  ProfilePage,
  UsersPage,
  AddUserPage,
  EditUserPage,
  AllClientsPage,
  AddClient,
  MyClientsPage,
  AddMyClientPage,
  WebSubmissionsPage,
  ReviewSubmisstionPage,
  EowListPage,
  EowCreatePage,
  EowEditPage,
  MyWebsubmissionPage,
  MyWebSubmissionView,
  ClientProfileReviewPage,
  ClientLabelsPage,
  ClientProfileSubmissionPage,
  InvoicesListPage,
  InvoiceEditPage,
  EditDefaultsPage,
} from '../../pages/admin';

const adminUrls = [
  {
    path: ADMIN_ROUTES.dashboardPage,
    component: <DashboardPage />,
  },
  {
    path: ADMIN_ROUTES.profilePage,
    component: <ProfilePage />,
  },
  {
    path: ADMIN_ROUTES.usersPage,
    component: <UsersPage />,
  },
  {
    path: ADMIN_ROUTES.addUserPage,
    component: <AddUserPage />,
  },
  {
    path: ADMIN_ROUTES.editUserPage,
    component: <EditUserPage />,
  },
  {
    path: ADMIN_ROUTES.allClientsPage,
    component: <AllClientsPage />,
  },
  {
    path: ADMIN_ROUTES.addClientPage,
    component: <AddClient />,
  },
  {
    path: ADMIN_ROUTES.myClientsPage,
    component: <MyClientsPage />,
  },
  {
    path: ADMIN_ROUTES.addMyClientsPage,
    component: <AddMyClientPage />,
  },
  {
    path: ADMIN_ROUTES.webSubmissionsPage,
    component: <WebSubmissionsPage />,
  },
  {
    path: ADMIN_ROUTES.reviewSubmissionsPage,
    component: <ReviewSubmisstionPage />,
  },
  {
    path: ADMIN_ROUTES.eowsListPage,
    component: <EowListPage />,
  },
  {
    path: ADMIN_ROUTES.eowsAddPage,
    component: <EowCreatePage />,
  },
  {
    path: ADMIN_ROUTES.eowsEditPage,
    component: <EowEditPage />,
  },
  {
    path: ADMIN_ROUTES.myWebSubmissionPage,
    component: <MyWebsubmissionPage />,
  },
  {
    path: ADMIN_ROUTES.myWebSubmissionViewPage,
    component: <MyWebSubmissionView />,
  },
  {
    path: ADMIN_ROUTES.clientReviewPage,
    component: <ClientProfileReviewPage />,
  },
  {
    path: ADMIN_ROUTES.clientLabelsPage,
    component: <ClientLabelsPage />,
  },
  {
    path: ADMIN_ROUTES.clientProfileSubmissionPage,
    component: <ClientProfileSubmissionPage />,
  },
  {
    path: ADMIN_ROUTES.invoicesPage,
    component: <InvoicesListPage />,
  },
  {
    path: ADMIN_ROUTES.invoiceEditPage,
    component: <InvoiceEditPage />,
  },
  {
    path: ADMIN_ROUTES.editDefaultsPage,
    component: <EditDefaultsPage />,
  },
];

export default adminUrls;
