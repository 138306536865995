import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Alert } from 'reactstrap';
import { Form, Field } from 'react-final-form';
import { useNavigate } from 'react-router-dom';
import { InputFieldWithIcon } from '../../../components/form';
import i18n from '../../../app/i18n';
import cms from '../../../app/api/cms';

const CreateClientModelForm = ({ onCancelHandler, submissionData }) => {
  const [formFieldValues, setFormFieldValues] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const validate = (values) => {
    const errors = {};

    if (!values.client_name) {
      errors.client_name = i18n.t('formErrors.requiredField');
    }
    return errors;
  };

  useEffect(() => {
    let splitName = submissionData.full_name.split(' ')[0];
    let splitSurname =
      submissionData.full_name.split(' ').length > 1 ? submissionData.full_name.split(' ')[1] : '';

    let newObj = {
      client_name: splitName,
      client_surname: splitSurname,
      client_email: submissionData.email,
      client_phone: submissionData.phone,
      client_settlement: '',
      client_street: '',
      client_property_number: '',
    };
    setFormFieldValues(newObj);
  }, []);

  const onSubmit = async (values) => {
    let formData = new FormData();
    setErrorMessage('');
    formData.append('client_name', values.client_name);
    formData.append('client_surname', values.client_surname);
    formData.append('client_email', values.client_email);
    formData.append('client_phone', values.client_phone);
    formData.append('client_settlement', values.client_settlement);
    formData.append('client_street', values.client_street);
    formData.append('client_property_number', values.client_property_number);

    let isErrors = false;
    let formErrors = {};

    await cms
      .WebSubmissionCreateClient(submissionData.id, formData)
      .then(function (response) {
        if (response.data.data !== undefined) {
          setSuccessMessage('Klientas sukurtas');
          window.location.reload();
        }
      })
      .catch(function (error) {
        if (error.response.data.error !== undefined) {
          setErrorMessage(i18n.t(`formErrors.${error.response.data.error}`));
        }
        if (error.response.data !== undefined) {
          for (const [key, value] of Object.entries(error.response.data)) {
            formErrors[key] = i18n.t(`formError.${value}`);
          }
          isErrors = true;
        }
      });
    if (isErrors) {
      return formErrors;
    }
  };

  const formFields = [
    {
      field: (
        <Field name='client_name'>
          {({ input, meta }) => (
            <InputFieldWithIcon
              placeholder={i18n.t('userProfileEditForm.name')}
              input={input}
              meta={meta}
              label={i18n.t('userProfileEditForm.name')}
            ></InputFieldWithIcon>
          )}
        </Field>
      ),
    },
    {
      field: (
        <Field name='client_surname'>
          {({ input, meta }) => (
            <InputFieldWithIcon
              placeholder={i18n.t('userProfileEditForm.surname')}
              input={input}
              meta={meta}
              label={i18n.t('userProfileEditForm.surname')}
            ></InputFieldWithIcon>
          )}
        </Field>
      ),
    },
    {
      field: (
        <Field name='client_email'>
          {({ input, meta }) => (
            <InputFieldWithIcon
              type='email'
              placeholder={i18n.t('userProfileEditForm.email')}
              input={input}
              meta={meta}
              label={i18n.t('userProfileEditForm.email')}
            ></InputFieldWithIcon>
          )}
        </Field>
      ),
    },
    {
      field: (
        <Field name='client_phone'>
          {({ input, meta }) => (
            <InputFieldWithIcon
              placeholder={i18n.t('userProfileEditForm.phone')}
              input={input}
              meta={meta}
              label={i18n.t('userProfileEditForm.phone')}
            ></InputFieldWithIcon>
          )}
        </Field>
      ),
    },
    {
      field: (
        <Field name='client_settlement'>
          {({ input, meta }) => (
            <InputFieldWithIcon
              placeholder={i18n.t('userProfileEditForm.settlement')}
              input={input}
              meta={meta}
              label={i18n.t('userProfileEditForm.settlement')}
            ></InputFieldWithIcon>
          )}
        </Field>
      ),
    },
    {
      field: (
        <Field name='client_street'>
          {({ input, meta }) => (
            <InputFieldWithIcon
              placeholder={i18n.t('userProfileEditForm.street')}
              input={input}
              meta={meta}
              label={i18n.t('userProfileEditForm.street')}
            ></InputFieldWithIcon>
          )}
        </Field>
      ),
    },
    {
      field: (
        <Field name='client_property_number'>
          {({ input, meta }) => (
            <InputFieldWithIcon
              placeholder={i18n.t('userProfileEditForm.propertyNumber')}
              input={input}
              meta={meta}
              label={i18n.t('userProfileEditForm.propertyNumber')}
            ></InputFieldWithIcon>
          )}
        </Field>
      ),
    },
  ];

  return (
    <>
      {errorMessage && errorMessage !== '' ? <Alert color='danger'>{errorMessage}</Alert> : ''}
      {successMessage && successMessage !== '' ? (
        <Alert color='success'>{successMessage}</Alert>
      ) : (
        ''
      )}
      <Form
        onSubmit={onSubmit}
        initialValues={formFieldValues}
        validate={validate}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} className='user-profile-update-form' noValidate>
            <div className='row'>
              {formFields.map((item, key) => (
                <div className='col-lg-6' key={`${key}-fields`}>
                  {item.field}
                </div>
              ))}
            </div>
            <div className='d-flex justify-content-between'>
              <button className='add-client-btn cancel-user-deletion' type='submit'>
                {i18n.t('userProfileEditForm.updateBtn')}
              </button>
              <button className='confirm-user-deletion' type='button' onClick={onCancelHandler}>
                {i18n.t('createClientModal.decline')}
              </button>
            </div>
            <div className='field-wrapper text-center keep-logged-in'></div>
          </form>
        )}
      />
    </>
  );
};

export default CreateClientModelForm;
