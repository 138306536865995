import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import cms from '../../../app/api/cms';
// import EditUserForm from './EditUserForm';
import PageTitle from '../../../components/pageTitle/PageTitle';
import '../editUser/EditUser.scss';
import EditDefaultForm from './EditDefaultForm';

const EditDefaultsPage = () => {
  const [defaults, setDefaults] = useState(null);
  const [loadData, setLoadData] = useState(false);

  useEffect(() => {
    cms
      .GetBasicDefaults()
      .then(function (response) {
        if (response.data.data !== undefined) {
          const dataObj = {};
          response.data.data.forEach((element) => {
            dataObj[element.field_name] = element.field_value;
          });
          setDefaults(dataObj);
          setLoadData(true);
        }
      })
      .catch(function (error) {
        console.log(error, 'error');
      });
  }, []);

  return (
    <div className='edit-user-page'>
      <PageTitle>Nustatymai</PageTitle>
      <div className='edit-user-form'>{loadData && <EditDefaultForm editData={defaults} />}</div>
    </div>
  );
};

export default EditDefaultsPage;
