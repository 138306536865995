import { useState } from 'react';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import i18n from '../../../app/i18n';
import cms from '../../../app/api/cms';
import './ClientArchiveModal.scss';

const SubmissionDeleteModal = ({ submissionId }) => {
  const [clientArchiveModalOpen, setClientArchiveModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const onClickHandler = async () => {
    await cms
      .WebSubmissionDelete(submissionId)
      .then(function (response) {
        if (response.data.message === 'action_success') {
          window.location.reload();
        }
      })
      .catch(function (error) {
        if (error.response.data.error !== undefined) {
          setErrorMessage(i18n.t(`formErrors.${error.response.data.error}`));
        }
      });
  };

  const onCancelHandler = () => {
    setClientArchiveModalOpen(false);
  };

  function clientArchiveModal() {
    return (
      <Modal className='delete-user-model' centered={true} isOpen={clientArchiveModalOpen} toggle={onCancelHandler}>
        <ModalHeader charcode='Y' toggle={() => setClientArchiveModalOpen(false)}></ModalHeader>
        <ModalBody>
          <p>{errorMessage}</p>
          <h6>Ištrinti užklausą</h6>
        </ModalBody>
        <ModalFooter className='d-flex justify-content-between'>
          <button onClick={onClickHandler} className='confirm-user-deletion'>
            Ištrinti
          </button>
          <button className='cancel-user-deletion' onClick={onCancelHandler}>
            Atšaukti
          </button>
        </ModalFooter>
      </Modal>
    );
  }

  return (
    <>
      {clientArchiveModal()}
      <button onClick={() => setClientArchiveModalOpen(true)} className='client-delete-btn'>
        Ištrinti
      </button>
    </>
  );
};

export default SubmissionDeleteModal;
