import { useState, useEffect } from 'react';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import i18n from '../../../app/i18n';
import CreateClientModelForm from './CreateClientModelForm';
import './ClientProfileEditClientModal.scss';
import { useSelector, useDispatch } from 'react-redux';
import cms from '../../../app/api/cms';
import { setLabels } from '../../../app/features/clients';

const ClientProfileEditClientModal = ({ client }) => {
  const dispatch = useDispatch();
  const [createClientModalOpen, setCreateClientModalOpen] = useState(false);

  useEffect(() => {
    loadLabels();
  }, []);

  const loadLabels = async () => {
    await cms
      .GetClientLabelsForSelect()
      .then(function (response) {
        if (response.data.data !== undefined) {
          dispatch(setLabels(response.data.data));
        }
      })
      .catch(function (error) {
        console.log(error, 'error');
      });
  };

  const onClickHandler = () => {};

  const onCancelHandler = () => {
    setCreateClientModalOpen(false);
  };

  function clientCreateModal() {
    return (
      <Modal
        className='delete-user-model'
        style={{ maxWidth: '80%' }}
        centered={true}
        isOpen={createClientModalOpen}
        toggle={onCancelHandler}
      >
        <ModalHeader charcode='Y' toggle={() => setCreateClientModalOpen(false)}>
          <h6>Koreguoti klientą</h6>
        </ModalHeader>
        <ModalBody>
          <CreateClientModelForm client={client} onCancelHandler={onCancelHandler} />
        </ModalBody>
      </Modal>
    );
  }

  return (
    <>
      {clientCreateModal()}
      <button onClick={() => setCreateClientModalOpen(true)} className='client-create-btn'>
        Koreguoti klientą
      </button>
    </>
  );
};

export default ClientProfileEditClientModal;
