const CheckBoxField = (props) => {
    return (
        <>
            <div className='form-check'>
                <input
                    type='checkbox'
                    id={props.input.name}
                    className={`form-check-input ${(props.meta.error || props.meta.submitError) && props.meta.touched ? 'is-invalid' : ''}`}
                    placeholder={props.placeholder}
                    {...props.input}
                />
                {props.label ? (
                    <label htmlFor={props.input.name} className='form-check-label'>
                        {props.label}
                    </label>
                ) : (
                    <></>
                )}

                {(props.meta.error || props.meta.submitError) && props.meta.touched && <div className='invalid-feedback'>{props.meta.error || props.meta.submitError}</div>}
            </div>
        </>
    );
};

export default CheckBoxField;
