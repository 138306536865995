import { useState } from 'react';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import i18n from '../../../app/i18n';
import ClientProfileAddSubmissionForm from './ClientProfileAddSubmissionForm';
import './ClientProfileAddSubmissionModal.scss';

const ClientProfileAddSubmissionModal = ({ client }) => {
    const [clientProfileSubModalOpen, setClientProfileSubModalOpen] = useState(false);

    const onClickHandler = () => {};

    const onCancelHandler = () => {
        setClientProfileSubModalOpen(false);
    };

    function clientProfileSubModal() {
        return (
            <Modal className='delete-user-model' centered={true} isOpen={clientProfileSubModalOpen} toggle={onCancelHandler}>
                <ModalHeader charcode='Y' toggle={() => setClientProfileSubModalOpen(false)}>
                    <h6>{i18n.t('clientPrifileSub.createSubmission')}</h6>
                </ModalHeader>
                <ModalBody>
                    <ClientProfileAddSubmissionForm client={client} onCancelHandler={onCancelHandler} />
                </ModalBody>
            </Modal>
        );
    }

    return (
        <>
            {clientProfileSubModal()}
            <div className='upper-side with-filter'>
                <button className='client-prof-add-sub' onClick={() => setClientProfileSubModalOpen(true)}>
                    Pridėti naują užklausą
                </button>
            </div>
        </>
    );
};

export default ClientProfileAddSubmissionModal;
