import { useState, useEffect } from 'react';
import PaginationGolbal from '../../../../components/pagination/PaginationGlobal';
import { Badge } from 'reactstrap';
import PageTitle from '../../../../components/pageTitle/PageTitle';
import { useSelector, useDispatch } from 'react-redux';
import ClientProfileAddSubmissionModal from '../../../../components/modals/clientProfileAddSubmission/ClientProfileAddSubmissionModal';
import './ClientProfileSubmissionPage.scss';
import { setStatuses } from '../../../../app/features/submissions';
import cms from '../../../../app/api/cms';
import i18n from '../../../../app/i18n';
import { Spinner, Alert } from 'reactstrap';

const SubmissionTable = ({ client }) => {
  const dispatch = useDispatch();
  const statuses = useSelector((state) => state.submissions.statuses);
  const [loadingStatuses, setLoadingStatuses] = useState(true);
  const [loadingRows, setLoadingRows] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [rows, setRows] = useState([]);
  const [totalItemsCount, setTotalItemsCount] = useState(0);

  useEffect(() => {
    if (statuses && statuses.length === 0) {
      loadStatuses();
    } else {
      setLoadingStatuses(false);
    }
    loadSubmissions();
  }, [statuses, currentPage]);

  const loadSubmissions = async () => {
    setRows([]);

    await cms
      .GetClientSubmissions(client.id, currentPage)
      .then(function (response) {
        if (response.data.data !== undefined) {
          setRows(response.data.data.rows);
          setTotalItemsCount(response.data.data.total_count);
          setLoadingRows(false);
        }
      })
      .catch(function (error) {});
  };

  const loadStatuses = async () => {
    await cms
      .GetSubmissionStatuses()
      .then(function (response) {
        if (response.data.data !== undefined) {
          dispatch(setStatuses(response.data.data));
          setLoadingStatuses(false);
        }
      })
      .catch(function (error) {});
  };

  function getWebsubmissionStatuses(item) {
    let statusClass = '';

    if (item.status === 'reviewed') {
      statusClass = 'secondary';
    } else if (item.status === 'in_review') {
      statusClass = 'info';
    } else if (item.status === 'new') {
      statusClass = 'success';
    } else if (item.status === 'need_callback') {
      statusClass = 'danger';
    } else {
      statusClass = 'secondary';
    }
    return <Badge color={statusClass}>{i18n.t(`webSubmisionsStatuses.${item.status}`)}</Badge>;
  }

  const handlePageChange = (newActivePage) => {
    setCurrentPage(newActivePage);
  };
  return (
    <div className='submission-client-prof-page' style={{ marginTop: '8px' }}>
      <PageTitle>Kliento užklausos</PageTitle>
      <div className='table-box'>
        <ClientProfileAddSubmissionModal client={client} />
        {loadingRows === true && loadingStatuses === true ? (
          <Spinner>Loading...</Spinner>
        ) : (
          <>
            <div className='table-all'>
              {rows && rows.length > 0 ? (
                <>
                  <div className='table-row main'>
                    <div style={{ width: '10%' }}>
                      <h6>ID</h6>
                    </div>
                    <div style={{ width: '15%' }}>
                      <h6>Sukurta</h6>
                    </div>
                    <div style={{ width: '20%' }}>
                      <h6>Statusas</h6>
                    </div>
                    <div style={{ width: '35%' }}>
                      <h6>Komentaras</h6>
                    </div>
                    <div style={{ width: '20%' }}>
                      <h6>Perskambinimo data</h6>
                    </div>
                  </div>
                  {rows.map((item) => (
                    <div className={`table-row row-${item.status}`} key={`${item.id}-submissions`}>
                      <div style={{ width: '10%' }} className='mobile-style'>
                        <h6 className='mobile-titles'>ID</h6>
                        <p>{item.id}</p>
                      </div>
                      <div style={{ width: '15%' }} className='mobile-style'>
                        <h6 className='mobile-titles'>SUKURTA</h6>
                        <p>{item.created_at}</p>
                      </div>
                      <div style={{ width: '20%' }} className='mobile-style'>
                        <h6 className='mobile-titles'>STATUSAS</h6>
                        <p>{getWebsubmissionStatuses(item)}</p>
                      </div>
                      <div style={{ width: '35%' }} className='mobile-style'>
                        <h6 className='mobile-titles'>KOMENTARAS</h6>
                        <p>{item.content}</p>
                      </div>
                      <div style={{ width: '20%' }} className='mobile-style'>
                        <h6 className='mobile-titles'>PERSKAMBINIMO DATA</h6>
                        <p>{item.callback_date ? item.callback_date : '-'}</p>
                      </div>
                    </div>
                  ))}
                  <PaginationGolbal
                    activePage={currentPage}
                    totalItemsCount={totalItemsCount}
                    handlePageChange={handlePageChange}
                    itemsCountPerPage={10}
                  />
                </>
              ) : (
                <Alert style={{ marginLeft: '20px', marginRight: '20px' }} color='primary'>
                  Užklausų nera.
                </Alert>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default SubmissionTable;
