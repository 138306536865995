import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Alert } from 'reactstrap';
import { useSelector } from 'react-redux';
import { Form, Field } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import { useNavigate } from 'react-router-dom';
import { InputFieldWithIcon, SearchableSelect } from '../../../../components/form';
import i18n from '../../../../app/i18n';
import validator from '../../../../app/utils/validations';
import { OnChange } from 'react-final-form-listeners';
import { FULL_ADMIN_ROUTES } from '../../../../app/routes/routeLinks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import cms from '../../../../app/api/cms';
import DatePicker from 'react-datepicker';

const EditForm = ({ invoice }) => {
  let goToPage = useNavigate();
  const vat_count = { percent: 21, forMath: 1.21 };
  const clientsForForm = useSelector((state) => state.clients.listForForm);
  const statuses = useSelector((state) => state.invoices.statuses);
  const types = useSelector((state) => state.invoices.types);
  const [clients, setClients] = useState([]);
  const [formFieldValues, setFormFieldValues] = useState(invoice);
  const [errorMessage, setErrorMessage] = useState('');
  const [issueDate, setIssueDate] = useState(new Date(invoice.issue_date));
  const [paymentTillDate, setPaymentTillDate] = useState(new Date(invoice.payment_till_date));

  useEffect(() => {
    if (clientsForForm && clientsForForm.length > 0) {
      let cleanClients = [];
      clientsForForm.forEach((el) => {
        let row = {
          value: el.id,
          label: el.is_company === 1 ? `${el.company_name} - ${el.name} ${el.surname}` : `${el.name} ${el.surname}`,
        };

        cleanClients = [...cleanClients, row];
      });
      setClients(cleanClients);

      let inStatuses = statuses.find((item) => item.value === invoice.status);
      let inTypes = types.find((item) => item.value === invoice.invoice_type);
      let inClients = cleanClients.find((item) => item.value === invoice.client_id);

      if (inStatuses !== undefined && inClients !== undefined && inTypes !== undefined) {
        let newInvoice = {
          ...formFieldValues,
          status: {
            value: inStatuses.value !== undefined && inStatuses.value ? inStatuses.value : '',
            label: inStatuses.label !== undefined && inStatuses.label ? inStatuses.label : '-',
          },
          invoice_type: {
            value: inTypes.value !== undefined && inTypes.value ? inTypes.value : '',
            label: inTypes.label !== undefined && inTypes.label ? inTypes.label : '-',
          },
          client_id: {
            value: inClients.value !== undefined && inClients.value ? inClients.value : '',
            label: inClients.label !== undefined && inClients.label ? inClients.label : '-',
          },
        };
        setFormFieldValues(newInvoice);
      } else if (inStatuses !== undefined) {
        let newInvoice = {
          ...formFieldValues,
          status: {
            value: inStatuses.value ? inStatuses.value : '',
            label: inStatuses.label ? inStatuses.label : '-',
          },
        };
        setFormFieldValues(newInvoice);
      } else if (inTypes !== undefined) {
        let newInvoice = {
          ...formFieldValues,
          invoice_type: {
            value: inTypes.value ? inTypes.value : '',
            label: inTypes.label ? inTypes.label : '-',
          },
        };
        setFormFieldValues(newInvoice);
      } else if (inClients !== undefined) {
        let newInvoice = {
          ...formFieldValues,
          client_id: {
            value: inClients.value ? inClients.value : '',
            label: inClients.label ? inClients.label : '-',
          },
        };
        setFormFieldValues(newInvoice);
      }
    }
  }, []);

  const validate = (values) => {
    const errors = {};

    if (!values.client_id) {
      errors.client_id = i18n.t('formErrors.requiredField');
    }

    if (!values.identifier_prefix) {
      errors.identifier_prefix = i18n.t('formErrors.requiredField');
    }

    if (!values.identifier) {
      errors.identifier = i18n.t('formErrors.requiredField');
    }

    if (!values.seller_email) {
      errors.seller_email = i18n.t('formErrors.requiredField');
    } else if (validator.isEmail(values.seller_email) === false) {
      errors.seller_email = i18n.t('formErrors.entarValidEmail');
    }

    if (!values.full_date_text) {
      errors.full_date_text = i18n.t('formErrors.requiredField');
    }

    if (!values.seller_name) {
      errors.seller_name = i18n.t('formErrors.requiredField');
    }

    if (!values.seller_code) {
      errors.seller_code = i18n.t('formErrors.requiredField');
    }

    if (!values.seller_phone) {
      errors.seller_phone = i18n.t('formErrors.requiredField');
    }

    if (!values.seller_bank) {
      errors.seller_bank = i18n.t('formErrors.requiredField');
    }

    if (!values.seller_bank_acc) {
      errors.seller_bank_acc = i18n.t('formErrors.requiredField');
    }

    if (!values.buyer_name) {
      errors.buyer_name = i18n.t('formErrors.requiredField');
    }

    if (!values.buyer_address) {
      errors.buyer_address = i18n.t('formErrors.requiredField');
    }

    if (values.buyer_email && validator.isEmail(values.buyer_email) === false) {
      errors.buyer_email = i18n.t('formErrors.entarValidEmail');
    }

    if (!values.seller_representative) {
      errors.seller_representative = i18n.t('formErrors.requiredField');
    }

    if (!values.invoice_type) {
      errors.invoice_type = i18n.t('formErrors.requiredField');
    }

    if (!values.status) {
      errors.status = i18n.t('formErrors.requiredField');
    }

    return errors;
  };

  const onSubmit = async (values) => {
    let formData = new FormData();
    setErrorMessage('');

    const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    formData.append('issue_date', issueDate ? new Date(issueDate).toLocaleDateString('lt-LT', options) : '');

    formData.append('client_id', values.client_id.value);
    formData.append('identifier_prefix', values.identifier_prefix);
    formData.append('identifier', values.identifier);
    formData.append('before_time_identifier_prefix', values.before_time_identifier_prefix ? values.before_time_identifier_prefix : new Date(issueDate).toLocaleDateString('lt-LT', options) );
    formData.append('before_time_identifier', values.before_time_identifier ? values.before_time_identifier : values.identifier);
    formData.append('full_date_text', values.full_date_text);
    formData.append('seller_name', values.seller_name);
    formData.append('seller_code', values.seller_code);
    formData.append('seller_phone', values.seller_phone);
    formData.append('seller_email', values.seller_email);
    formData.append('seller_bank', values.seller_bank);
    formData.append('seller_bank_acc', values.seller_bank_acc);
    formData.append('seller_representative', values.seller_representative);
    formData.append('seller_vat_code', values.seller_vat_code ? values.seller_vat_code : '');
    formData.append('buyer_name', values.buyer_name);
    formData.append('buyer_address', values.buyer_address);
    formData.append('buyer_phone', values.buyer_phone ? values.buyer_phone : '');
    formData.append('buyer_company_name', values.buyer_company_name ? values.buyer_company_name : '');
    formData.append('buyer_company_code', values.buyer_company_code ? values.buyer_company_code : '');
    formData.append('buyer_email', values.buyer_email ? values.buyer_email : '');
    formData.append('buyer_vat_code', values.buyer_vat_code ? values.buyer_vat_code : '');
    formData.append('grand_total', values.grand_total);
    formData.append('grand_total_in_text', values.grand_total_in_text);
    formData.append('total', values.total);
    formData.append('vat_amount', values.vat_amount);

    if (values.status && values.status.value) {
      formData.append('status', values.status.value);
    }

    if (values.invoice_type && values.invoice_type.value) {
      formData.append('invoice_type', values.invoice_type.value);
    }

    
    formData.append('payment_till_date', paymentTillDate ? new Date(paymentTillDate).toLocaleDateString('lt-LT', options) : '');

    if (values.invoice_items && values.invoice_items.length > 0) {
      values.invoice_items.forEach((element, index) => {
        if (element.id !== undefined) {
          formData.append(`invoice_items[${index}][id]`, element.id);
        }
        formData.append(`invoice_items[${index}][title]`, element.title);
        formData.append(`invoice_items[${index}][qty]`, element.qty);
        formData.append(`invoice_items[${index}][unit]`, element.unit);
        formData.append(`invoice_items[${index}][unit_price]`, element.unit_price);
        formData.append(`invoice_items[${index}][total_price]`, element.total_price);
        formData.append(`invoice_items[${index}][total_without_vat]`, element.total_without_vat);
        formData.append(`invoice_items[${index}][vat_amount]`, element.vat_amount);
      });
    }

    let isErrors = false;
    let formErrors = {};

    await cms
      .UpdateInvoice(invoice.id, formData)
      .then(function (response) {
        if (response.data.data !== undefined) {
          window.location.reload();
        }
      })
      .catch(function (error) {
        if (error.response.data.error !== undefined) {
          setErrorMessage(i18n.t(`formErrors.${error.response.data.error}`));
        }
        if (error.response.data !== undefined) {
          for (const [key, value] of Object.entries(error.response.data)) {
            formErrors[key] = i18n.t(`formError.${value}`);
          }
          isErrors = true;
        }
      });
    if (isErrors) {
      return formErrors;
    }
  };

  const onChangeClientIDHandler = (form, values, selectedId) => {
    console.log('selectedId-->', selectedId);
    let selectedClient = clientsForForm.filter((item) => Number(item.id) === Number(selectedId.value));

    if (selectedClient && selectedClient.length > 0) {
      let client = selectedClient[0];
      form.reset({
        ...values,
        buyer_name: `${client.name} ${client.surname}`,
        buyer_phone: client.phone,
        buyer_company_name: client.company_name,
        buyer_company_code: client.company_code,
        buyer_vat_code: client.company_vat,
        buyer_email: client.email,
        buyer_address: `${client.settlement} ${client.street} ${client.property_number}`,
      });
    }
  };

  const onChangeQty = (name, newQty, form, values) => {
    let cleanIndex = name.replace('invoice_items[', '');
    let cleanIndex2 = cleanIndex.replace(']', '');
    let fieldIndex = Number(cleanIndex2);

    let total_price = Number(values.invoice_items[fieldIndex].unit_price) * Number(newQty);
    let vat_amount = Number(parseFloat(total_price - (Number(values.invoice_items[fieldIndex].unit_price) * Number(newQty)) / vat_count.forMath).toFixed(2));

    let newRow = {
      title: values.invoice_items[fieldIndex].title,
      qty: newQty,
      unit: values.invoice_items[fieldIndex].unit,
      unit_price: values.invoice_items[fieldIndex].unit_price,
      total_without_vat: total_price - vat_amount,
      vat_amount: vat_amount,
      total_price: total_price,
    };

    let invoice_items = values.invoice_items;
    invoice_items[fieldIndex] = newRow;

    form.reset({
      ...values,
      invoice_items,
    });
  };

  const onChangeUnitPrice = (name, newUnitPrice, form, values) => {
    let lastCharacter = newUnitPrice[newUnitPrice.length - 1];

    if (lastCharacter !== '.') {
      let cleanIndex = name.replace('invoice_items[', '');
      let cleanIndex2 = cleanIndex.replace(']', '');
      let fieldIndex = Number(cleanIndex2);

      let total_price = parseFloat(newUnitPrice) * Number(values.invoice_items[fieldIndex].qty);
      let vat_amount = Number(parseFloat(total_price - (Number(newUnitPrice) * Number(values.invoice_items[fieldIndex].qty)) / vat_count.forMath).toFixed(2));

      let newRow = {
        title: values.invoice_items[fieldIndex].title,
        qty: values.invoice_items[fieldIndex].qty,
        unit: values.invoice_items[fieldIndex].unit,
        unit_price: Number(newUnitPrice),
        total_without_vat: total_price - vat_amount,
        vat_amount: vat_amount,
        total_price: total_price,
      };

      let invoice_items = values.invoice_items;
      invoice_items[fieldIndex] = newRow;

      form.reset({
        ...values,
        invoice_items,
      });
    }
  };

  const recalculateTotals = (form, values) => {
    let items = values.invoice_items;
    let itemsTotalSum = 0;
    let itemsVat = 0;
    let itemsTotalWithoutVat = 0;

    if (items && items.length > 0) {
      items.forEach((element) => {
        itemsTotalWithoutVat += Number(element.total_without_vat);
        itemsVat += Number(element.vat_amount);
        itemsTotalSum += Number(element.total_price);
      });
    }

    form.reset({
      ...values,
      total: Number(itemsTotalWithoutVat),
      vat_amount: Number(itemsVat),
      grand_total: Number(itemsTotalSum),
    });
  };

  const formClientSelect = [
    {
      field: (
        <Field name='client_id'>
          {({ input, meta }) => <SearchableSelect placeholder={i18n.t('eowPages.empty')} options={clients} input={input} meta={meta} label={i18n.t('eowPages.selectClient')}></SearchableSelect>}
        </Field>
      ),
    },
  ];

  const formClientFields = [
    {
      field: (
        <Field name='buyer_company_name'>{({ input, meta }) => <InputFieldWithIcon placeholder='Įmonės pavadinimas' input={input} meta={meta} label='Įmonės pavadinimas'></InputFieldWithIcon>}</Field>
      ),
    },
    {
      field: <Field name='buyer_company_code'>{({ input, meta }) => <InputFieldWithIcon placeholder='Įmonės kodas' input={input} meta={meta} label='Įmonės kodas'></InputFieldWithIcon>}</Field>,
    },
    {
      field: <Field name='buyer_vat_code'>{({ input, meta }) => <InputFieldWithIcon placeholder='Įmonės PVM kodas' input={input} meta={meta} label='Įmonės PVM kodas'></InputFieldWithIcon>}</Field>,
    },
    {
      field: (
        <Field name='buyer_name'>
          {({ input, meta }) => <InputFieldWithIcon placeholder={i18n.t('eowPages.client_full_name')} input={input} meta={meta} label={i18n.t('eowPages.client_full_name')}></InputFieldWithIcon>}
        </Field>
      ),
    },
    {
      field: <Field name='buyer_address'>{({ input, meta }) => <InputFieldWithIcon placeholder='Adresas' input={input} meta={meta} label='Adresas'></InputFieldWithIcon>}</Field>,
    },
    {
      field: (
        <Field name='buyer_phone'>
          {({ input, meta }) => <InputFieldWithIcon type='text' placeholder={i18n.t('eowPages.client_phone')} input={input} meta={meta} label={i18n.t('eowPages.client_phone')}></InputFieldWithIcon>}
        </Field>
      ),
    },
    {
      field: (
        <Field name='buyer_email'>
          {({ input, meta }) => <InputFieldWithIcon type='text' placeholder={i18n.t('eowPages.client_email')} input={input} meta={meta} label={i18n.t('eowPages.client_email')}></InputFieldWithIcon>}
        </Field>
      ),
    },
  ];

  const sellerFields = [
    {
      field: (
        <Field name='seller_name'>
          {({ input, meta }) => <InputFieldWithIcon type='text' placeholder='Įmonės pavadinimas' input={input} meta={meta} label='Įmonės pavadinimas'></InputFieldWithIcon>}
        </Field>
      ),
    },
    {
      field: <Field name='seller_code'>{({ input, meta }) => <InputFieldWithIcon type='text' placeholder='Įmonės kodas' input={input} meta={meta} label='Įmonės kodas'></InputFieldWithIcon>}</Field>,
    },
    {
      field: (
        <Field name='seller_vat_code'>
          {({ input, meta }) => <InputFieldWithIcon type='text' placeholder='PVM mokėtojo kodas' input={input} meta={meta} label='PVM mokėtojo kodas'></InputFieldWithIcon>}
        </Field>
      ),
    },
    {
      field: (
        <Field name='seller_phone'>
          {({ input, meta }) => <InputFieldWithIcon type='text' placeholder='Įmonės telefonas' input={input} meta={meta} label='Įmonės telefonas'></InputFieldWithIcon>}
        </Field>
      ),
    },
    {
      field: (
        <Field name='seller_email'>
          {({ input, meta }) => <InputFieldWithIcon type='text' placeholder='Įmonės el.paštas' input={input} meta={meta} label='Įmonės el.paštas'></InputFieldWithIcon>}
        </Field>
      ),
    },
    {
      field: (
        <Field name='seller_bank'>
          {({ input, meta }) => <InputFieldWithIcon type='text' placeholder='Įmonės banko info' input={input} meta={meta} label='Įmonės banko info'></InputFieldWithIcon>}
        </Field>
      ),
    },
    {
      field: <Field name='seller_bank_acc'>{({ input, meta }) => <InputFieldWithIcon type='text' placeholder='Įmonės iban' input={input} meta={meta} label='Įmonės iban'></InputFieldWithIcon>}</Field>,
    },
    {
      field: (
        <Field name='seller_representative'>
          {({ input, meta }) => <InputFieldWithIcon type='text' placeholder='Sąskaitą išrašė' input={input} meta={meta} label='Sąskaitą išrašė'></InputFieldWithIcon>}
        </Field>
      ),
    },
  ];

  const lastFields = [
    {
      field: (
        <Field name='vat_amount'>
          {({ input, meta }) => (
            <InputFieldWithIcon type='text' placeholder={`PVM (${vat_count.percent}%)`} input={input} meta={meta} label={`PVM (${vat_count.percent}%)`} disabled={true}></InputFieldWithIcon>
          )}
        </Field>
      ),
    },
    {
      field: (
        <Field name='total'>
          {({ input, meta }) => <InputFieldWithIcon type='text' placeholder='Mokėtina suma be PVM' input={input} meta={meta} label='Mokėtina suma be PVM' disabled={true}></InputFieldWithIcon>}
        </Field>
      ),
    },
    {
      field: (
        <Field name='grand_total'>
          {({ input, meta }) => <InputFieldWithIcon type='text' placeholder='Mokėtina suma su PVM' input={input} meta={meta} label='Mokėtina suma su PVM' disabled={true}></InputFieldWithIcon>}
        </Field>
      ),
    },
    {
      field: (
        <Field name='grand_total_in_text'>
          {({ input, meta }) => <InputFieldWithIcon type='text' placeholder='Suma žodžiais' input={input} meta={meta} label='Suma žodžiais'></InputFieldWithIcon>}
        </Field>
      ),
    },
    {
      field: (
        <Field name='status'>{({ input, meta }) => <SearchableSelect placeholder={i18n.t('eowPages.empty')} options={statuses} input={input} meta={meta} label='Statusas'></SearchableSelect>}</Field>
      ),
    },
    {
      field: (
        <Field name='invoice_type'>{({ input, meta }) => <SearchableSelect placeholder={i18n.t('eowPages.empty')} options={types} input={input} meta={meta} label='Tipas'></SearchableSelect>}</Field>
      ),
    },
  ];

  const invoiceFields = [
    {
      field: (
        <Field name='identifier_prefix'>
          {({ input, meta }) => <InputFieldWithIcon type='text' placeholder='Sąskaitos nr pirma dalis' input={input} meta={meta} label='Sąskaitos nr pirma dalis'></InputFieldWithIcon>}
        </Field>
      ),
    },
    {
      field: (
        <Field name='identifier'>
          {({ input, meta }) => <InputFieldWithIcon type='text' placeholder='Sąskaitos nr antra dalis' input={input} meta={meta} label='Sąskaitos nr antra dalis'></InputFieldWithIcon>}
        </Field>
      ),
    },
    {
      field: (
        <Field name='full_date_text'>{({ input, meta }) => <InputFieldWithIcon type='text' placeholder='Datos antraštė' input={input} meta={meta} label='Datos antraštė'></InputFieldWithIcon>}</Field>
      ),
    },
  ];

  const invoiceFields2 = [
    {
      field: (
        <Field name='before_time_identifier_prefix'>
          {({ input, meta }) => <InputFieldWithIcon type='text' placeholder='Išankstinės sąskaitos nr pirma dalis' input={input} meta={meta} label='Išankstinės sąskaitos nr pirma dalis'></InputFieldWithIcon>}
        </Field>
      ),
    },
    {
      field: (
        <Field name='before_time_identifier'>
          {({ input, meta }) => <InputFieldWithIcon type='text' placeholder='Išankstinės sąskaitos nr antra dalis' input={input} meta={meta} label='Išankstinės sąskaitos nr antra dalis'></InputFieldWithIcon>}
        </Field>
      ),
    },
  ];

  return (
    <>
      {errorMessage && errorMessage !== '' ? <Alert color='danger'>{errorMessage}</Alert> : ''}
      <Form
        onSubmit={onSubmit}
        mutators={{
          ...arrayMutators,
        }}
        initialValues={formFieldValues}
        validate={validate}
        render={({
          form: {
            mutators: { push, pop },
          },
          handleSubmit,
          form,
          submitting,
          pristine,
          values,
        }) => (
          <form onSubmit={handleSubmit} noValidate>
            <OnChange name='client_id'>
              {(value) => {
                onChangeClientIDHandler(form, values, value);
              }}
            </OnChange>
            <div className='row'>
              <div className='col-12'>
                <div className='add-form-form'>
                  <div className='row'>
                    <div className='col-lg-12'>
                      <h3 className='form-title'>PIRKĖJAS</h3>
                      <hr />
                    </div>
                  </div>
                  <div className='row'>
                    {formClientSelect.map((item, key) => (
                      <div className='col-lg-6' key={`${key}-fields-select`}>
                        {item.field}
                      </div>
                    ))}
                  </div>
                  <div className='row'>
                    {formClientFields.map((item, key) => (
                      <div className='col-lg-6' key={`${key}-fields-client`}>
                        {item.field}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col-12'>
                <div className='add-form-form mt-3'>
                  <div className='row'>
                    <div className='col-lg-12'>
                      <h3 className='form-title'>PARDAVĖJAS</h3>
                      <hr />
                    </div>
                  </div>
                  <div className='row'>
                    {sellerFields.map((item, key) => (
                      <div className='col-lg-6' key={`${key}-fields-seller`}>
                        {item.field}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col-12'>
                <div className='add-form-form mt-3'>
                  <div className='row'>
                    <div className='col-lg-12'>
                      <h3 className='form-title'>SĄSKAITOS INFO</h3>
                      <hr />
                    </div>
                  </div>
                  <div className='row'>
                    {invoiceFields.map((item, key) => (
                      <div className='col-lg-6' key={`${key}-fields-invoice`}>
                        {item.field}
                      </div>
                    ))}
                  </div>
                  <div className='row'>
                    <div className='col-lg-6'>
                      <label className='input-label ps-0 pe-0'>Išrašymo data</label>
                      <DatePicker dateFormat='yyyy-MM-dd' selected={issueDate} onChange={(date) => setIssueDate(date)} />
                    </div>

                    <div className='col-lg-6'>
                      <label className='input-label ps-0 pe-0'>Apmokėti iki</label>
                      <DatePicker dateFormat='yyyy-MM-dd' selected={paymentTillDate} onChange={(date) => setPaymentTillDate(date)} />
                    </div>
                  </div>
                  <div className='row'>
                  {invoiceFields2.map((item, key) => (
                      <div className='col-lg-6' key={`${key}-fields-invoice-2`}>
                        {item.field}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col-12'>
                <div className='add-form-form mt-3'>
                  <div className='row'>
                    <div className='col-8'>
                      <h3 className='form-title d-inline-block mb-0'>SĄSKAITOS PREKĖS</h3>
                    </div>
                    <div className='col-4'></div>
                    <div className='col-lg-12'>
                      <hr />
                    </div>
                  </div>
                  <FieldArray name='invoice_items'>
                    {({ fields }) =>
                      fields.map((name, index) => (
                        <React.Fragment key={name}>
                          <OnChange name={`${name}.qty`}>
                            {(value) => {
                              onChangeQty(name, value, form, values);
                            }}
                          </OnChange>
                          <OnChange name={`${name}.unit_price`}>
                            {(value) => {
                              onChangeUnitPrice(name, value, form, values);
                            }}
                          </OnChange>
                          <OnChange name={`${name}.total_price`}>
                            {(value) => {
                              recalculateTotals(form, values);
                            }}
                          </OnChange>
                          <div className='row'>
                            <div className='col-1'>
                              <button type='button' className='icon-button-danger' style={{ marginRight: '10px' }} onClick={() => fields.remove(index)}>
                                <FontAwesomeIcon icon={faTrash} />
                              </button>
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-12'>
                              <Field name={`${name}.title`}>
                                {({ input, meta }) => (
                                  <InputFieldWithIcon type='text' placeholder={i18n.t('eowPages.itemTitle')} input={input} meta={meta} label={i18n.t('eowPages.itemTitle')}></InputFieldWithIcon>
                                )}
                              </Field>
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-lg-3 col-md-4 col-sm-12'>
                              <Field name={`${name}.qty`}>
                                {({ input, meta }) => (
                                  <InputFieldWithIcon type='text' placeholder={i18n.t('eowPages.itemQty')} input={input} meta={meta} label={i18n.t('eowPages.itemQty')}></InputFieldWithIcon>
                                )}
                              </Field>
                            </div>
                            <div className='col-lg-3 col-md-4 col-sm-12'>
                              <Field name={`${name}.unit`}>
                                {({ input, meta }) => (
                                  <InputFieldWithIcon type='text' placeholder={i18n.t('eowPages.itemUnit')} input={input} meta={meta} label={i18n.t('eowPages.itemUnit')}></InputFieldWithIcon>
                                )}
                              </Field>
                            </div>
                            <div className='col-lg-3 col-md-4 col-sm-12'>
                              <Field name={`${name}.unit_price`}>
                                {({ input, meta }) => (
                                  <InputFieldWithIcon
                                    type='text'
                                    placeholder={i18n.t('eowPages.itemUnitPrice')}
                                    input={input}
                                    meta={meta}
                                    label={i18n.t('eowPages.itemUnitPrice')}
                                  ></InputFieldWithIcon>
                                )}
                              </Field>
                            </div>
                            <div className='col-lg-3 col-md-4 col-sm-12'>
                              <Field name={`${name}.total_without_vat`}>
                                {({ input, meta }) => <InputFieldWithIcon type='text' placeholder='Kaina be PVM' input={input} meta={meta} label='Kaina be PVM' disabled={true}></InputFieldWithIcon>}
                              </Field>
                            </div>
                            <div className='col-lg-3 col-md-4 col-sm-12'>
                              <Field name={`${name}.vat_amount`}>
                                {({ input, meta }) => <InputFieldWithIcon type='text' placeholder='PVM' input={input} meta={meta} label='PVM' disabled={true}></InputFieldWithIcon>}
                              </Field>
                            </div>
                            <div className='col-lg-3 col-md-4 col-sm-12'>
                              <Field name={`${name}.total_price`}>
                                {({ input, meta }) => (
                                  <InputFieldWithIcon
                                    type='text'
                                    placeholder={i18n.t('eowPages.itemTotalPrice')}
                                    input={input}
                                    meta={meta}
                                    label={i18n.t('eowPages.itemTotalPrice')}
                                    disabled={true}
                                  ></InputFieldWithIcon>
                                )}
                              </Field>
                            </div>
                            <div className='col-12'>
                              <hr className='mt-0 ' />
                            </div>
                          </div>
                        </React.Fragment>
                      ))
                    }
                  </FieldArray>
                  <div className='row'>
                    <div className='col-8'>
                      <button
                        type='button'
                        className='icon-button'
                        style={{ marginRight: '10px' }}
                        onClick={() =>
                          push('invoice_items', {
                            title: '',
                            qty: 1,
                            unit: '',
                            unit_price: 0,
                            total_without_vat: 0,
                            vat_amount: 0,
                            total_price: 0,
                          })
                        }
                      >
                        <FontAwesomeIcon icon={faPlus} />
                      </button>
                      <h3 className='form-title d-inline-block mb-0'>Pridėti</h3>
                    </div>
                    <div className='col-4'></div>
                  </div>
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col-12'>
                <div className='add-form-form mt-3'>
                  <div className='row'>
                    {lastFields.map((item, key) => (
                      <div className='col-lg-6' key={`${key}-fields-last`}>
                        {item.field}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col-12'>
                <div className='add-form-form mt-3'>
                  <div className='d-flex justify-content-between mob-three-btn'>
                    <button className='add-form-btn three-mb' type='submit'>
                      {i18n.t('userProfileEditForm.updateBtn')}
                    </button>
                    {invoice.downloadLink && invoice.downloadLink !== '' && (
                      <a className='btn btn-secondary three-mb' href={invoice.downloadLink} download>
                        Atsisiųsti
                      </a>
                    )}
                    <Link to={FULL_ADMIN_ROUTES.invoicesPage} className='back-button'>
                      {i18n.t('back.backButton')}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </form>
        )}
      />
    </>
  );
};

export default EditForm;
