import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Alert } from 'reactstrap';
import { useSelector } from 'react-redux';
import { Form, Field } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import { useNavigate } from 'react-router-dom';
import { InputFieldWithIcon, SearchableSelect, CheckBoxField } from '../../../../components/form';
import i18n from '../../../../app/i18n';
import validator from '../../../../app/utils/validations';
import { OnChange } from 'react-final-form-listeners';
import { FULL_ADMIN_ROUTES } from '../../../../app/routes/routeLinks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import cms from '../../../../app/api/cms';

const EditForm = ({ eowId, eowData }) => {
    let goToPage = useNavigate();
    const statuses = useSelector((state) => state.eows.statuses);
    const [formFieldValues, setFormFieldsValues] = useState(eowData);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    useEffect(() => {
        let statusObj = statuses.filter((item) => item.value === eowData.status);
        if (statusObj && statusObj.length > 0) {
            setFormFieldsValues({ ...eowData, status: { value: statusObj[0].value, label: statusObj[0].label } });
        }
    }, []);

    const validate = (values) => {
        const errors = {};

        if (!values.client_full_name) {
            errors.client_full_name = i18n.t('formErrors.requiredField');
        }

        if (!values.client_phone) {
            errors.client_phone = i18n.t('formErrors.requiredField');
        }

        if (!values.client_email) {
            errors.client_email = i18n.t('formErrors.requiredField');
        } else if (validator.isEmail(values.client_email) === false) {
            errors.client_email = i18n.t('formErrors.entarValidEmail');
        }

        if (!values.property_settlement) {
            errors.property_settlement = i18n.t('formErrors.requiredField');
        }

        if (!values.property_street) {
            errors.property_street = i18n.t('formErrors.requiredField');
        }

        if (!values.property_nr) {
            errors.property_nr = i18n.t('formErrors.requiredField');
        }

        if (!values.grand_total) {
            errors.grand_total = i18n.t('formErrors.requiredField');
        } else if (Number(values.grand_total) <= 0) {
            errors.grand_total = i18n.t('formErrors.cannotBeEqualOrBelowZero');
        }

        if (Number(values.discount) >= Number(values.total)) {
            errors.discount = i18n.t('formErrors.discountIsGreaterThanTotal');
        }

        return errors;
    };

    const onSubmit = async (values) => {
        let formData = new FormData();
        setErrorMessage('');
        setSuccessMessage('');

        formData.append('client_id', values.client_id);
        formData.append('client_full_name', values.client_full_name);
        formData.append('client_phone', values.client_phone);
        formData.append('client_email', values.client_email);
        formData.append('property_settlement', values.property_settlement);
        formData.append('property_street', values.property_street);
        formData.append('property_nr', values.property_nr);
        formData.append('device_model', values.device_model);
        formData.append('device_population', values.device_population);
        formData.append('device_performance', values.device_performance);
        formData.append('device_cover', values.device_cover);
        formData.append('device_efficiency', values.device_efficiency);
        formData.append('comments', values.comments);
        formData.append('total', values.total);
        formData.append('discount', values.discount);
        formData.append('grand_total', values.grand_total);
        formData.append('status', values.status.value);

        if (values.items && values.items.length > 0) {
            values.items.forEach((element, index) => {
                if (element.id !== undefined) {
                    formData.append(`items[${index}][id]`, element.id);
                }
                formData.append(`items[${index}][title]`, element.title);
                formData.append(`items[${index}][qty]`, element.qty);
                formData.append(`items[${index}][unit]`, element.unit);
                formData.append(`items[${index}][unit_price]`, element.unit_price);
                formData.append(`items[${index}][total_price]`, element.total_price);
                formData.append(`items[${index}][price_included]`, element.price_included && element.price_included === 1 ? 1 : 0);
            });
        }

        let isErrors = false;
        let formErrors = {};

        await cms
            .UpdateEow(eowId, formData)
            .then(function (response) {
                if (response.data.data !== undefined) {
                    setSuccessMessage('Issaugota');
                }
            })
            .catch(function (error) {
                if (error.response.data.error !== undefined) {
                    setErrorMessage(i18n.t(`formErrors.${error.response.data.error}`));
                }
                if (error.response.data !== undefined) {
                    for (const [key, value] of Object.entries(error.response.data)) {
                        formErrors[key] = i18n.t(`formError.${value}`);
                    }
                    isErrors = true;
                }
            });
        if (isErrors) {
            return formErrors;
        }
    };

    const onChangeQty = (name, newQty, form, values) => {
        let cleanIndex = name.replace('items[', '');
        let cleanIndex2 = cleanIndex.replace(']', '');
        let fieldIndex = Number(cleanIndex2);

        let newRow = {
            title: values.items[fieldIndex].title,
            qty: newQty,
            unit: values.items[fieldIndex].unit,
            unit_price: values.items[fieldIndex].unit_price,
            total_price: Number(values.items[fieldIndex].unit_price) * Number(newQty),
            price_included: values.items[fieldIndex].price_included,
        };

        let items = values.items;
        items[fieldIndex] = newRow;

        form.reset({
            ...values,
            items,
        });
    };

    const onChangeUnitPrice = (name, newUnitPrice, form, values) => {
        let cleanIndex = name.replace('items[', '');
        let cleanIndex2 = cleanIndex.replace(']', '');
        let fieldIndex = Number(cleanIndex2);

        let newRow = {
            title: values.items[fieldIndex].title,
            qty: values.items[fieldIndex].qty,
            unit: values.items[fieldIndex].unit,
            unit_price: Number(newUnitPrice),
            total_price: Number(newUnitPrice) * Number(values.items[fieldIndex].qty),
            price_included: values.items[fieldIndex].price_included,
        };

        let items = values.items;
        items[fieldIndex] = newRow;

        form.reset({
            ...values,
            items,
        });
    };

    const onChangeIncludedPrice = (name, value, form, values) => {
        let cleanIndex = name.replace('items[', '');
        let cleanIndex2 = cleanIndex.replace(']', '');
        let fieldIndex = Number(cleanIndex2);

        if (value === 0) {
            let newRow = {
                title: values.items[fieldIndex].title,
                qty: values.items[fieldIndex].qty,
                unit: values.items[fieldIndex].unit,
                unit_price: values.items[fieldIndex].unit_price,
                total_price: values.items[fieldIndex].total_price,
                price_included: value,
            };
            let items = values.items;
            items[fieldIndex] = newRow;

            form.reset({
                ...values,
                items,
            });
        } else if (value === 1) {
            let newRow = {
                title: values.items[fieldIndex].title,
                qty: values.items[fieldIndex].qty,
                unit: values.items[fieldIndex].unit,
                unit_price: 0,
                total_price: 0,
                price_included: value,
            };
            let items = values.items;
            items[fieldIndex] = newRow;

            form.reset({
                ...values,
                items,
            });
        }
    };

    const recalculateTotals = (form, values) => {
        let items = values.items;
        let itemsTotalSum = 0;

        if (items && items.length > 0) {
            items.forEach((element) => {
                itemsTotalSum += Number(element.total_price);
            });
        }

        form.reset({
            ...values,
            total: Number(itemsTotalSum),
            grand_total: values.discount && values.discount !== '' && Number(values.discount) > 0 ? Number(itemsTotalSum) - Number(values.discount) : Number(itemsTotalSum),
        });
    };

    const formClientFields = [
        {
            field: (
                <Field name='client_full_name'>
                    {({ input, meta }) => (
                        <InputFieldWithIcon placeholder={i18n.t('eowPages.client_full_name')} input={input} meta={meta} label={i18n.t('eowPages.client_full_name')}></InputFieldWithIcon>
                    )}
                </Field>
            ),
        },
        {
            field: (
                <Field name='client_phone'>
                    {({ input, meta }) => (
                        <InputFieldWithIcon type='text' placeholder={i18n.t('eowPages.client_phone')} input={input} meta={meta} label={i18n.t('eowPages.client_phone')}></InputFieldWithIcon>
                    )}
                </Field>
            ),
        },
        {
            field: (
                <Field name='client_email'>
                    {({ input, meta }) => (
                        <InputFieldWithIcon type='text' placeholder={i18n.t('eowPages.client_email')} input={input} meta={meta} label={i18n.t('eowPages.client_email')}></InputFieldWithIcon>
                    )}
                </Field>
            ),
        },
        {
            field: (
                <Field name='property_settlement'>
                    {({ input, meta }) => (
                        <InputFieldWithIcon
                            type='text'
                            placeholder={i18n.t('eowPages.property_settlement')}
                            input={input}
                            meta={meta}
                            label={i18n.t('eowPages.property_settlement')}
                        ></InputFieldWithIcon>
                    )}
                </Field>
            ),
        },
        {
            field: (
                <Field name='property_street'>
                    {({ input, meta }) => (
                        <InputFieldWithIcon type='text' placeholder={i18n.t('eowPages.property_street')} input={input} meta={meta} label={i18n.t('eowPages.property_street')}></InputFieldWithIcon>
                    )}
                </Field>
            ),
        },
        {
            field: (
                <Field name='property_nr'>
                    {({ input, meta }) => (
                        <InputFieldWithIcon type='text' placeholder={i18n.t('eowPages.property_nr')} input={input} meta={meta} label={i18n.t('eowPages.property_nr')}></InputFieldWithIcon>
                    )}
                </Field>
            ),
        },
    ];

    const deviceFields = [
        {
            field: (
                <Field name='device_model'>
                    {({ input, meta }) => (
                        <InputFieldWithIcon type='text' placeholder={i18n.t('eowPages.device_model')} input={input} meta={meta} label={i18n.t('eowPages.device_model')}></InputFieldWithIcon>
                    )}
                </Field>
            ),
        },
        {
            field: (
                <Field name='device_population'>
                    {({ input, meta }) => (
                        <InputFieldWithIcon
                            type='device_population'
                            placeholder={i18n.t('eowPages.device_population')}
                            input={input}
                            meta={meta}
                            label={i18n.t('eowPages.device_population')}
                        ></InputFieldWithIcon>
                    )}
                </Field>
            ),
        },
        {
            field: (
                <Field name='device_performance'>
                    {({ input, meta }) => (
                        <InputFieldWithIcon
                            type='text'
                            placeholder={i18n.t('eowPages.device_performance')}
                            input={input}
                            meta={meta}
                            label={i18n.t('eowPages.device_performance')}
                        ></InputFieldWithIcon>
                    )}
                </Field>
            ),
        },
        {
            field: (
                <Field name='device_cover'>
                    {({ input, meta }) => (
                        <InputFieldWithIcon type='text' placeholder={i18n.t('eowPages.device_cover')} input={input} meta={meta} label={i18n.t('eowPages.device_cover')}></InputFieldWithIcon>
                    )}
                </Field>
            ),
        },
        {
            field: (
                <Field name='device_efficiency'>
                    {({ input, meta }) => (
                        <InputFieldWithIcon type='text' placeholder={i18n.t('eowPages.device_efficiency')} input={input} meta={meta} label={i18n.t('eowPages.device_efficiency')}></InputFieldWithIcon>
                    )}
                </Field>
            ),
        },
    ];

    const lastFields = [
        {
            field: (
                <Field name='comments'>
                    {({ input, meta }) => <InputFieldWithIcon type='text' placeholder={i18n.t('eowPages.comments')} input={input} meta={meta} label={i18n.t('eowPages.comments')}></InputFieldWithIcon>}
                </Field>
            ),
        },
        {
            field: (
                <Field name='total'>
                    {({ input, meta }) => (
                        <InputFieldWithIcon type='text' placeholder={i18n.t('eowPages.total')} input={input} meta={meta} label={i18n.t('eowPages.total')} disabled={true}></InputFieldWithIcon>
                    )}
                </Field>
            ),
        },
        {
            field: (
                <Field name='discount'>
                    {({ input, meta }) => <InputFieldWithIcon type='text' placeholder={i18n.t('eowPages.discount')} input={input} meta={meta} label={i18n.t('eowPages.discount')}></InputFieldWithIcon>}
                </Field>
            ),
        },
        {
            field: (
                <Field name='grand_total'>
                    {({ input, meta }) => (
                        <InputFieldWithIcon
                            type='text'
                            placeholder={i18n.t('eowPages.grand_total')}
                            input={input}
                            meta={meta}
                            label={i18n.t('eowPages.grand_total')}
                            disabled={true}
                        ></InputFieldWithIcon>
                    )}
                </Field>
            ),
        },
        {
            field: (
                <Field name='status'>
                    {({ input, meta }) => (
                        <SearchableSelect placeholder={i18n.t('eowPages.empty')} options={statuses} input={input} meta={meta} label={i18n.t('eowPages.selectStatus')}></SearchableSelect>
                    )}
                </Field>
            ),
        },
    ];

    return (
        <>
            {errorMessage && errorMessage !== '' ? <Alert color='danger'>{errorMessage}</Alert> : ''}
            {successMessage && successMessage !== '' ? <Alert color='success'>{successMessage}</Alert> : ''}
            <Form
                onSubmit={onSubmit}
                mutators={{
                    ...arrayMutators,
                }}
                initialValues={formFieldValues}
                validate={validate}
                render={({
                    form: {
                        mutators: { push, pop },
                    },
                    handleSubmit,
                    form,
                    submitting,
                    pristine,
                    values,
                }) => (
                    <form onSubmit={handleSubmit} noValidate>
                        <OnChange name='discount'>
                            {(value) => {
                                recalculateTotals(form, values);
                            }}
                        </OnChange>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='add-form-form'>
                                    <div className='row'>
                                        <div className='col-lg-12'>
                                            <h3 className='form-title'>{i18n.t('eowPages.clientFields')}</h3>
                                            <hr />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        {formClientFields.map((item, key) => (
                                            <div className='col-lg-6' key={`${key}-fields-client`}>
                                                {item.field}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-12'>
                                <div className='add-form-form mt-3'>
                                    <div className='row'>
                                        <div className='col-lg-12'>
                                            <h3 className='form-title'>Nuotekų valymo įrenginys</h3>
                                            <hr />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        {deviceFields.map((item, key) => (
                                            <div className='col-lg-6' key={`${key}-fields-device`}>
                                                {item.field}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-12'>
                                <div className='add-form-form mt-3'>
                                    <div className='row'>
                                        <div className='col-8'>
                                            <button
                                                type='button'
                                                className='icon-button'
                                                style={{ marginRight: '10px' }}
                                                onClick={() =>
                                                    push('items', {
                                                        title: '',
                                                        qty: 1,
                                                        unit: '',
                                                        unit_price: 0,
                                                        total_price: 0,
                                                    })
                                                }
                                            >
                                                <FontAwesomeIcon icon={faPlus} />
                                            </button>
                                            <h3 className='form-title d-inline-block mb-0'>Buitinių nuotekų sistemos sąmata</h3>
                                        </div>
                                        <div className='col-4'></div>
                                        <div className='col-lg-12'>
                                            <hr />
                                        </div>
                                    </div>
                                    <FieldArray name='items'>
                                        {({ fields }) =>
                                            fields.map((name, index) => (
                                                <React.Fragment key={name}>
                                                    <OnChange name={`${name}.price_included`}>
                                                        {(value) => {
                                                            onChangeIncludedPrice(name, value, form, values);
                                                        }}
                                                    </OnChange>
                                                    <OnChange name={`${name}.qty`}>
                                                        {(value) => {
                                                            onChangeQty(name, value, form, values);
                                                        }}
                                                    </OnChange>
                                                    <OnChange name={`${name}.unit_price`}>
                                                        {(value) => {
                                                            onChangeUnitPrice(name, value, form, values);
                                                        }}
                                                    </OnChange>
                                                    <OnChange name={`${name}.total_price`}>
                                                        {(value) => {
                                                            recalculateTotals(form, values);
                                                        }}
                                                    </OnChange>
                                                    <div className='d-flex justify-content-between align-items-center mb-2'>
                                                        <div>
                                                            <Field name={`${name}.price_included`} type='checkbox' format={(v) => v === 1} parse={(v) => (v ? 1 : 0)}>
                                                                {({ input, meta }) => <CheckBoxField input={input} meta={meta} label={i18n.t('eowPages.price_included')}></CheckBoxField>}
                                                            </Field>
                                                        </div>
                                                        <div>
                                                            <button type='button' className='icon-button-danger' style={{ marginRight: '10px' }} onClick={() => fields.remove(index)}>
                                                                <FontAwesomeIcon icon={faTrash} />
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <Field name={`${name}.title`}>
                                                                {({ input, meta }) => (
                                                                    <InputFieldWithIcon
                                                                        type='text'
                                                                        placeholder={i18n.t('eowPages.itemTitle')}
                                                                        input={input}
                                                                        meta={meta}
                                                                        label={i18n.t('eowPages.itemTitle')}
                                                                    ></InputFieldWithIcon>
                                                                )}
                                                            </Field>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-lg-3 col-md-4 col-sm-12'>
                                                            <Field name={`${name}.qty`}>
                                                                {({ input, meta }) => (
                                                                    <InputFieldWithIcon
                                                                        type='text'
                                                                        placeholder={i18n.t('eowPages.itemQty')}
                                                                        input={input}
                                                                        meta={meta}
                                                                        label={i18n.t('eowPages.itemQty')}
                                                                    ></InputFieldWithIcon>
                                                                )}
                                                            </Field>
                                                        </div>
                                                        <div className='col-lg-3 col-md-4 col-sm-12'>
                                                            <Field name={`${name}.unit`}>
                                                                {({ input, meta }) => (
                                                                    <InputFieldWithIcon
                                                                        type='text'
                                                                        placeholder={i18n.t('eowPages.itemUnit')}
                                                                        input={input}
                                                                        meta={meta}
                                                                        label={i18n.t('eowPages.itemUnit')}
                                                                    ></InputFieldWithIcon>
                                                                )}
                                                            </Field>
                                                        </div>
                                                        <div className='col-lg-3 col-md-4 col-sm-12'>
                                                            <Field name={`${name}.unit_price`}>
                                                                {({ input, meta }) => (
                                                                    <InputFieldWithIcon
                                                                        type='text'
                                                                        placeholder={i18n.t('eowPages.itemUnitPrice')}
                                                                        input={input}
                                                                        meta={meta}
                                                                        label={i18n.t('eowPages.itemUnitPrice')}
                                                                    ></InputFieldWithIcon>
                                                                )}
                                                            </Field>
                                                        </div>
                                                        <div className='col-lg-3 col-md-4 col-sm-12'>
                                                            <Field name={`${name}.total_price`}>
                                                                {({ input, meta }) => (
                                                                    <InputFieldWithIcon
                                                                        type='text'
                                                                        placeholder={i18n.t('eowPages.itemTotalPrice')}
                                                                        input={input}
                                                                        meta={meta}
                                                                        label={i18n.t('eowPages.itemTotalPrice')}
                                                                        disabled={true}
                                                                    ></InputFieldWithIcon>
                                                                )}
                                                            </Field>
                                                        </div>
                                                        <div className='col-12'>
                                                            <hr className='mt-0 ' />
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                            ))
                                        }
                                    </FieldArray>
                                </div>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-12'>
                                <div className='add-form-form mt-3'>
                                    <div className='row'>
                                        {lastFields.map((item, key) => (
                                            <div className='col-lg-6' key={`${key}-fields-last`}>
                                                {item.field}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-12'>
                                <div className='add-form-form mt-3'>
                                    <div className='d-flex justify-content-between mob-three-btn'>
                                        <button className='add-form-btn three-mb' type='submit'>
                                            {i18n.t('userProfileEditForm.updateBtn')}
                                        </button>

                                        {eowData.downloadLink && eowData.downloadLink !== '' && (
                                            <a className='btn btn-secondary three-mb' href={eowData.downloadLink} download>
                                                Atsisiųsti
                                            </a>
                                        )}

                                        <Link to={FULL_ADMIN_ROUTES.eowsListPage} className='back-button'>
                                            {i18n.t('back.backButton')}
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                )}
            />
            {errorMessage && errorMessage !== '' ? <Alert color='danger'>{errorMessage}</Alert> : ''}
            {successMessage && successMessage !== '' ? <Alert color='success'>{successMessage}</Alert> : ''}
        </>
    );
};

export default EditForm;
