import { useState } from 'react';
import { Link } from 'react-router-dom';
import PaginationGolbal from '../../../components/pagination/PaginationGlobal';
import ClientArchiveModal from '../../../components/modals/clientArchive/ClientArchiveModal';
import PageTitle from '../../../components/pageTitle/PageTitle';
import MyClientsFilter from './MyClientsFilter';
import './MyClientsPage.scss';

const MyClientsPage = () => {
    const [currentPage, setCurrentPage] = useState(1);

    const handlePageChange = (newActivePage) => {
        setCurrentPage(newActivePage);
    };

    return (
        <div className='my-clients-page'>
            <PageTitle>Mano klientai</PageTitle>
            <div className='table-box'>
                <div className='upper-side with-filter'>
                    <MyClientsFilter />

                    <div>
                        <Link to='/cms/admin/my-clients/add'>Pridėti klientą</Link>
                    </div>
                </div>
                <div className='table-all'>
                    <div className='table-row main'>
                        <div style={{ width: '10%' }}>
                            <h6>ID</h6>
                        </div>
                        <div style={{ width: '10%' }}>
                            <h6>Žyma</h6>
                        </div>
                        <div style={{ width: '30%' }}>
                            <h6>Klientas</h6>
                        </div>
                        <div style={{ width: '20%' }}>
                            <h6>Adresas</h6>
                        </div>
                        <div style={{ width: '20%' }}>
                            <h6>Aministratorius</h6>
                        </div>
                        <div style={{ width: '10%' }}>
                            <h6>Veiksmai</h6>
                        </div>
                    </div>
                    <div className='table-row'>
                        <div style={{ width: '10%' }}>
                            <p>123456</p>
                        </div>
                        <div style={{ width: '10%' }}>
                            <p>Blogas klientas</p>
                        </div>
                        <div style={{ width: '30%' }}>
                            <p>
                                Edwina Baldetti3r4r3r3r3 <br />
                                ebaldetti14@theguardianffffff.com
                                <br />
                                +37061020386
                            </p>
                        </div>
                        <div style={{ width: '20%' }}>
                            <p>Ukmerges g. 300, Vilnius m. sav.</p>
                        </div>
                        <div style={{ width: '20%' }}>
                            <p>Edwina Baldettikrkkr</p>
                        </div>
                        <div style={{ width: '10%' }} className='d-flex flex-column align-items-center'>
                            <Link to='/cms/admin/clients/:rowID/review' className='seen-btn'>
                                <span>Peržiūrėti</span>
                            </Link>
                            <ClientArchiveModal />
                        </div>
                    </div>
                </div>
                <div className='pagination-container'>
                    <PaginationGolbal activePage={currentPage} totalItemsCount={100} handlePageChange={handlePageChange} />
                </div>
            </div>
        </div>
    );
};

export default MyClientsPage;
