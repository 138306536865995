export const AUTH_ROUTES = {
  loginPage: 'login',
};

export const FULL_AUTH_ROUTES = {
  loginPage: '/auth/login',
};
export const ADMIN_ROUTES = {
  dashboardPage: 'admin/dashboard',
  profilePage: 'admin/profile',
  usersPage: 'admin/users',
  addUserPage: 'admin/users/add',
  editUserPage: 'admin/users/:userID/edit',
  allClientsPage: 'admin/clients',
  addClientPage: 'admin/clients/add',
  myClientsPage: 'admin/my-clients',
  addMyClientsPage: 'admin/my-clients/add',
  oneClientViewPage: 'admin/clients/rowID/review',
  webSubmissionsPage: 'admin/web-submissions',
  reviewSubmissionsPage: 'admin/web-submissions/:rowID/review',
  eowsListPage: 'admin/eows',
  eowsAddPage: 'admin/eows/add',
  eowsEditPage: 'admin/eows/:rowId/edit',
  myWebSubmissionPage: 'admin/my-web-submissions',
  myWebSubmissionViewPage: 'admin/my-web-submissions/:rowID/review',
  clientReviewPage: 'admin/clients/:rowID/review',
  clientSubmissionsPage: 'admin/clients/:rowID/submissions',
  clientEowsPage: 'admin/clients/:rowID/eows',
  clientInvoicesPage: 'admin/clients/:rowID/invoices',
  clientLabelsPage: 'admin/client-labels',
  clientProfileSubmissionPage: 'admin/clients/:rowID/submissions',
  invoicesPage: 'admin/invoices',
  invoiceEditPage: 'admin/invoices/:rowID/edit',
  editDefaultsPage: 'admin/defaults',
};

export const FULL_ADMIN_ROUTES = {
  dashboardPage: '/cms/admin/dashboard',
  profilePage: '/cms/admin/profile',
  usersPage: '/cms/admin/users',
  addUserPage: '/cms/admin/users/add',
  editUserPage: '/cms/admin/users/:userID/edit',
  allClientsPage: '/cms/admin/clients',
  addClientPage: '/cms/admin/clients/add',
  myClientsPage: '/cms/admin/my-clients',
  addMyClientsPage: '/cms/admin/my-clients/add',
  oneClientViewPage: '/cms/admin/clients/:rowID/review',
  webSubmissionsPage: '/cms/admin/web-submissions',
  reviewSubmissionsPage: '/cms/admin/web-submissions/:rowID/review',
  eowsListPage: '/cms/admin/eows',
  eowsAddPage: '/cms/admin/eows/add',
  eowsEditPage: '/cms/admin/eows/:rowId/edit',
  myWebSubmissionPage: '/cms/admin/my-web-submissions',
  myWebSubmissionViewPage: '/cms/admin/my-web-submissions/:rowID/review',
  clientReviewPage: '/cms/admin/clients/:rowID/review',
  clientSubmissionsPage: '/cms/admin/clients/:rowID/submissions',
  clientEowsPage: '/cms/admin/clients/:rowID/eows',
  clientInvoicesPage: '/cms/admin/clients/:rowID/invoices',
  clientLabelsPage: '/cms/admin/client-labels',
  clientProfileSubmissionPage: '/cms/admin/clients/:rowID/submissions',
  invoicesPage: '/cms/admin/invoices',
  invoiceEditPage: '/cms/admin/invoices/:rowID/edit',
  editDefaultsPage: '/cms/admin/defaults',
};
