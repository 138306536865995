import { createSlice } from '@reduxjs/toolkit';

export const BasicConfigsReducer = createSlice({
    name: 'basicConfigs',
    initialState: {
        fields: [],
    },
    reducers: {
        setFields: (state, { payload }) => {
            state.fields = payload;
        },
    },
});

export const { setFields } = BasicConfigsReducer.actions;
export default BasicConfigsReducer.reducer;
