import Pagination from 'react-js-pagination';
import './PaginationGolbal.scss';

const PaginationGolbal = ({ activePage, totalItemsCount, handlePageChange, itemsCountPerPage }) => {
  return (
    <div className='d-flex justify-content-end'>
      <Pagination
        linkClassPrev='preeeee'
        activePage={activePage}
        itemsCountPerPage={itemsCountPerPage ? itemsCountPerPage : 20}
        totalItemsCount={totalItemsCount}
        pageRangeDisplayed={5}
        onChange={handlePageChange}
        itemClass='page-item'
        hideFirstLastPages={true}
        nextPageText='>'
        prevPageText='<'
        hideDisabled={true}
      />
    </div>
  );
};

export default PaginationGolbal;
