import { NavLink } from 'react-router-dom';
import logo from '../../../../images/logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse, faUsers, faUserGroup, faFileSignature, faFile, faChalkboardUser, faTags, faCogs } from '@fortawesome/free-solid-svg-icons';
import i18n from '../../../../app/i18n';
import { FULL_ADMIN_ROUTES } from '../../../../app/routes/routeLinks';
import { slide as Menu } from 'react-burger-menu';
import './SideMenu.scss';

const SideMenu = ({ closeSideBar }) => {
  return (
    <>
      <div className='side-menu'>
        <div className='logo-box'>
          <a href='/'>
            <img src={logo} alt='logo' />
          </a>
        </div>
        <ul>
          <li>
            <NavLink to='/cms/admin/dashboard' className={(navData) => (navData.isActive ? 'active side' : 'side')} onClick={closeSideBar}>
              <FontAwesomeIcon icon={faHouse} />
              <span className='ml-3'>Dashboard</span>
            </NavLink>
          </li>
          <li>
            <NavLink to='/cms/admin/web-submissions' className={(navData) => (navData.isActive ? 'active side' : 'side')} onClick={closeSideBar}>
              <FontAwesomeIcon icon={faChalkboardUser} />
              <span>{i18n.t('sideMenu.gerneralRequests')}</span>
            </NavLink>
          </li>
          <li>
            <NavLink to='/cms/admin/clients' className={(navData) => (navData.isActive ? 'active side' : 'side')} onClick={closeSideBar}>
              <FontAwesomeIcon icon={faUserGroup} />
              <span>{i18n.t('sideMenu.clients')}</span>
            </NavLink>
          </li>
          <li>
            <NavLink to={FULL_ADMIN_ROUTES.clientLabelsPage} className={(navData) => (navData.isActive ? 'active side' : 'side')} onClick={closeSideBar}>
              <FontAwesomeIcon icon={faTags} />
              <span>{i18n.t('sideMenu.clientTags')}</span>
            </NavLink>
          </li>

          <li>
            <NavLink to={FULL_ADMIN_ROUTES.eowsListPage} className={(navData) => (navData.isActive ? 'active side' : 'side')} onClick={closeSideBar}>
              <FontAwesomeIcon icon={faFile} />
              <span>{i18n.t('sideMenu.estimatesOfWorks')}</span>
            </NavLink>
          </li>
          <li>
            <NavLink to={FULL_ADMIN_ROUTES.invoicesPage} className={(navData) => (navData.isActive ? 'active side' : 'side')} onClick={closeSideBar}>
              <FontAwesomeIcon icon={faFileSignature} />
              <span>{i18n.t('sideMenu.invoices')}</span>
            </NavLink>
          </li>
          <li>
            <NavLink to='/cms/admin/users' className={(navData) => (navData.isActive ? 'active side' : 'side')} onClick={closeSideBar}>
              <FontAwesomeIcon icon={faUsers} />
              <span>{i18n.t('sideMenu.users')}</span>
            </NavLink>
          </li>
          <li>
            <NavLink to='/cms/admin/defaults' className={(navData) => (navData.isActive ? 'active side' : 'side')} onClick={closeSideBar}>
              <FontAwesomeIcon icon={faCogs} />
              <span>{i18n.t('sideMenu.defaults')}</span>
            </NavLink>
          </li>
          {/* <p className='between-text'>{i18n.t('sideMenu.myPart')}</p>
        <li>
          <NavLink
            to='/cms/admin/my-clients'
            className={(navData) => (navData.isActive ? 'active side' : 'side')}
          >
            <FontAwesomeIcon icon={faUserGroup} />
            <span>{i18n.t('sideMenu.clients')}</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            to='/cms/admin/my-web-submissions'
            className={(navData) => (navData.isActive ? 'active side' : 'side')}
          >
            <FontAwesomeIcon icon={faChalkboardUser} />
            <span>{i18n.t('sideMenu.gerneralRequests')}</span>
          </NavLink>
        </li> */}
        </ul>
      </div>
    </>
  );
};

export default SideMenu;
