import { useState, useEffect } from 'react';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import { Spinner } from 'reactstrap';
import './AssignMeModal.scss';
import cms from '../../../app/api/cms';
import { useNavigate } from 'react-router-dom';

const CreateInvoiceModal = () => {
    let goToPage = useNavigate();
    const [assignMeModalOpen, setAssignMeModalOpen] = useState(false);

    useEffect(() => {
        if (assignMeModalOpen === true) {
            createInvoice();
        }
    }, [assignMeModalOpen]);

    const onClickHandler = () => {};

    const onCancelHandler = () => {
        setAssignMeModalOpen(false);
    };

    const createInvoice = async () => {
        await cms
            .CreateInvoice()
            .then(function (response) {
                console.log('res->', response);
                if (response.data.data !== undefined) {
                    ///cms/admin/invoices/:rowID/edit
                    goToPage(`/cms/admin/invoices/${response.data.data.id}/edit`);
                }
            })
            .catch(function (error) {});
    };

    function assignArchiveModal() {
        return (
            <Modal className='delete-user-model' centered={true} isOpen={assignMeModalOpen} toggle={onCancelHandler}>
                <ModalHeader charcode='Y' toggle={() => setAssignMeModalOpen(false)}></ModalHeader>
                <ModalBody style={{ textAlign: 'center' }}>
                    <Spinner>Loading...</Spinner>
                    <span>Generuojama...</span>
                </ModalBody>
            </Modal>
        );
    }

    return (
        <>
            {assignArchiveModal()}
            <button onClick={() => setAssignMeModalOpen(true)} className='assign-me-btn'>
                Pridėti sąskaitą
            </button>
        </>
    );
};

export default CreateInvoiceModal;
