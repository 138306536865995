import PageTitle from '../../../components/pageTitle/PageTitle';
import CreateClientModal from '../../../components/modals/createClient/CreateClientModal';
import RecallModal from '../../../components/modals/recall/RecallModal';
import './MyWebSubmissionView.scss';

const MyWebSubmissionView = () => {
    return (
        <div className='review-sub-page'>
            <PageTitle>Užklausos peržiūra</PageTitle>

            <div className='table-box'>
                <div className='upper-side with-filter'>
                    <h6>Užklausos ID: #1</h6>
                </div>
                <div className='table-all d-flex'>
                    <div className='table-row d-flex flex-column border'>
                        <div className='d-flex border-bottom align-items-center p-2'>
                            <div style={{ width: '30%' }}>
                                <h6>ID</h6>
                            </div>
                            <div style={{ width: '70%' }}>
                                <p>123456</p>
                            </div>
                        </div>

                        <div className='d-flex border-bottom align-items-center p-2'>
                            <div style={{ width: '30%' }}>
                                <h6>Sukurta</h6>
                            </div>
                            <div style={{ width: '70%' }}>
                                <p>2020 10 20 10:29</p>
                            </div>
                        </div>

                        <div className='d-flex border-bottom align-items-center p-2'>
                            <div style={{ width: '30%' }}>
                                <h6>Statusas</h6>
                            </div>
                            <div style={{ width: '70%' }}>
                                <p>geras statusas</p>
                            </div>
                        </div>

                        <div className='d-flex border-bottom align-items-center p-2'>
                            <div style={{ width: '30%' }}>
                                <h6>Vardas Pavardė</h6>
                            </div>
                            <div style={{ width: '70%' }}>
                                <p>Edwina Baldetti3r4r3r3r3</p>
                            </div>
                        </div>
                        <div className='d-flex border-bottom align-items-center p-2'>
                            <div style={{ width: '30%' }}>
                                <h6>Telefonas</h6>
                            </div>
                            <div style={{ width: '70%' }}>
                                <p>+37061020386</p>
                            </div>
                        </div>

                        <div className='d-flex border-bottom align-items-center p-2'>
                            <div style={{ width: '30%' }}>
                                <h6>Perskambinimo data</h6>
                            </div>
                            <div style={{ width: '70%' }}>
                                <p>2020 10 20 10:29</p>
                            </div>
                        </div>

                        <div className='d-flex align-items-center p-2'>
                            <div style={{ width: '30%' }}>
                                <h6>Komentaras</h6>
                            </div>
                            <div style={{ width: '70%' }}>
                                <p>hbfeiufr eirfuwef eirgwu4g eierfue3qfr iwyrfeq rfiqefr efquegrbfwq erfhqb</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='buttons'>
                    <RecallModal />
                    <CreateClientModal />
                </div>
            </div>
        </div>
    );
};

export default MyWebSubmissionView;
