import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import { faPenToSquare, faFileDownload } from '@fortawesome/free-solid-svg-icons';
import PageTitle from '../../../../components/pageTitle/PageTitle';
import DeleteUserModal from '../../../../components/modals/deleteUser/DeleteUserModal';
import PaginationGolbal from '../../../../components/pagination/PaginationGlobal';
import cms from '../../../../app/api/cms';
import '../../eowpages/eowList/EowListPage.scss';
import StatusBadge from './StatusBadge';
import TypeBadge from './TypeBadge';
import { Alert } from 'reactstrap';
import CreateInvoiceModal from '../../../../components/modals/createInvoice/CreateInvoiceModal';
import InvoiceFilter from './InvoiceFilter';

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

const InvoicesListPage = () => {
    const query = useQuery();
    const navigate = useNavigate();
    const statuses = useSelector((state) => state.invoices.statuses);
    const types = useSelector((state) => state.invoices.types);
    const [rows, setRows] = useState([]);
    const [totalItemsCount, setTotalItemsCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [clientFullName, setClientFullName] = useState(query.get('clientFullName') || '');
    const [invoiceNumber, setInvoiceNumber] = useState(query.get('invoiceNumber') || '');
    const [status, setStatus] = useState(query.get('status') || '');
    const [invoice_type, setInvoiceType] = useState(query.get('invoice_type') || '');
    const [queryString, setQueryString] = useState('');
    const [filterFields, setFilterFields] = useState({clientFullName: query.get('clientFullName') || '', invoiceNumber: query.get('invoiceNumber') || '', status: query.get('status') || '', invoice_type: query.get('invoice_type')});

    useEffect(() => {
        const clientFullName = query.get('clientFullName') || '';
        const invoiceNumber = query.get('invoiceNumber') || '';
        const status = query.get('status') || '';
        const invoice_type = query.get('invoice_type') || '';
        const currentPage = Number(query.get('page')) || 1;

        if (clientFullName) {
            setClientFullName(clientFullName);
        }
        if (invoiceNumber) {
            setInvoiceNumber(invoiceNumber);
        }
        if (status) {
            setStatus(status);
        }
        if (invoice_type) {
            setInvoiceType(invoice_type);
        }
        if (currentPage) {
            setCurrentPage(currentPage);
        }

        setFilterFields({clientFullName, invoiceNumber, status, invoice_type});

        const queryStringT = `?clientFullName=${clientFullName}&invoiceNumber=${invoiceNumber}&status=${status}&invoice_type=${invoice_type}&page=${currentPage}`;
        //setQueryString(queryStringT);

        handleSearch(clientFullName, invoiceNumber, status, invoice_type, currentPage);
        loadRows(queryStringT);
    }, [queryString]);

    const handleSearch = (clientFullNameR, invoiceNumberR, statusR, invoiceTypeR, currentPageR) => {
        const params = new URLSearchParams();
        if (statusR) params.set('status', statusR);
        if (invoiceTypeR) params.set('invoice_type', invoiceTypeR);
        if (clientFullNameR) params.set('clientFullName', clientFullNameR);
        if (invoiceNumberR) params.set('invoiceNumber', invoiceNumberR);
        if (currentPageR) params.set('page', currentPageR);
        setQueryString(`?${params.toString()}`);
        navigate(`?${params.toString()}`);
    };

    const handleSearchFilter = (filterFields) => {
        handleSearch(
            filterFields.clientFullName !== undefined ? filterFields.clientFullName : '',
            filterFields.invoiceNumber !== undefined ? filterFields.invoiceNumber : '',
            filterFields.status !== undefined ? filterFields.status : '',
            filterFields.invoice_type !== undefined ? filterFields.invoice_type : '',
            1
        );
    };

    const handleDeleteRow = async (rowId) => {
        await cms
            .DeleteInvoice(rowId)
            .then(function (response) {
                if (response.data.message === 'invoice_deleted') {
                    loadRows(queryString);
                }
            })
            .catch(function (error) {
                console.log(error, 'error');
            });
    };

    const loadRows = async (queryString) => {
        setRows([]);

        await cms
            .GetInvoices(queryString)
            .then(function (response) {
                if (response.data.data !== undefined) {
                    setRows(response.data.data.rows);
                    setTotalItemsCount(response.data.data.total_count);
                }
            })
            .catch(function (error) {});
    };

    const handlePageChange = (newActivePage) => {
        setCurrentPage(newActivePage);
        handleSearch(clientFullName, invoiceNumber, status, newActivePage);
    };

    function StatusHtml(elStatus) {
        if (statuses && statuses.length > 0) {
            let findStatus = statuses.find((el) => el.value === elStatus);
            if (findStatus !== -1) {
                return <StatusBadge status={elStatus} text={findStatus.label} />;
            }
        }
    }

    function TypeHtml(elType) {
        if (types && types.length > 0) {
          let findType = types.find((el) => el.value === elType);
          if (findType !== -1) {
            return <TypeBadge type={elType} text={findType.label} />;
          }
        }
      }

    return (
        <div className='users-page'>
            <PageTitle>Sąskaitos</PageTitle>
            <div className='row'>
                <div className='col-12'>
                    <InvoiceFilter currentFilterFields={filterFields} makeSearch={handleSearchFilter} invoiceStatuses={statuses} invoceTypes={types} />
                </div>
            </div>
            <div className='table-box'>
                <div className='upper-side'>
                    <CreateInvoiceModal />
                </div>
                {rows && rows.length > 0 ? (
                    <>
                        <div className='table-all'>
                            <div className='table-row main'>
                                <div style={{ width: '10%' }}>
                                    <h6>ID</h6>
                                </div>
                                <div style={{ width: '30%' }}>
                                    <h6>Sąskaita / Klientas</h6>
                                </div>
                                <div style={{ width: '15%' }}>
                                    <h6>Statusas</h6>
                                </div>
                                <div style={{ width: '15%' }}>
                                    <h6>Tipas</h6>
                                </div>
                                <div style={{ width: '20%' }}>
                                    <h6>Suma</h6>
                                </div>
                                <div style={{ width: '10%' }}>
                                    <h6>Veiksmai</h6>
                                </div>
                            </div>
                            {rows.map((row) => (
                                <div className='table-row' key={`${row.id}-rows`}>
                                    <div style={{ width: '10%' }} className='mobile-style'>
                                        <h6 className='mobile-titles'>ID</h6>
                                        <p>{row.id}</p>
                                    </div>
                                    <div style={{ width: '30%' }} className='mobile-style'>
                                        <h6 className='mobile-titles'>SĄSKAITA / KLIENTAS</h6>
                                        <p>
                                            {row.invoice_type == 'before_time' ? (<>NR. {row.before_time_identifier_prefix}-{row.before_time_identifier}</>) : (<>NR. {row.identifier_prefix}-{row.identifier}</>)}
                                            
                                            <br />
                                            {row.buyer_name}
                                        </p>
                                    </div>
                                    <div style={{ width: '15%' }} className='mobile-style'>
                                        <h6 className='mobile-titles'>STATUSAS</h6>
                                        <p>{StatusHtml(row.status)}</p>
                                    </div>
                                    <div style={{ width: '15%' }} className='mobile-style'>
                                        <h6 className='mobile-titles'>TIPAS</h6>
                                        <p>{TypeHtml(row.invoice_type)}</p>
                                    </div>
                                    <div style={{ width: '20%' }} className='mobile-style'>
                                        <h6 className='mobile-titles'>SUMA</h6>
                                        <p>{row.grand_total} &euro;</p>
                                    </div>
                                    <div style={{ width: '10%' }} className='mobile-style'>
                                        <h6 className='mobile-titles'>VEIKSMAI</h6>
                                        <p className='icons-table mobile-small-icon'>
                                            <Link to={`/cms/admin/invoices/${row.id}/edit`}>
                                                <FontAwesomeIcon icon={faPenToSquare} className='mob-r' />
                                            </Link>
                                            {row.downloadLink ? (
                                                <a className='text-info' href={row.downloadLink} download='download'>
                                                    <FontAwesomeIcon icon={faFileDownload} className='mob-r' />
                                                </a>
                                            ) : (
                                                ''
                                            )}
                                            <DeleteUserModal rowId={row.id} onSuccess={handleDeleteRow} textContent={`Ar tikrai norite ištrinti?`} title={`Sąskaitos #${row.id} trinimas`} />
                                        </p>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className='pagination-container'>
                            <PaginationGolbal activePage={currentPage} totalItemsCount={totalItemsCount} handlePageChange={handlePageChange} itemsCountPerPage={10} />
                        </div>
                    </>
                ) : (
                    <Alert style={{ marginLeft: '20px', marginRight: '20px' }} color='primary'>
                        Sąskaitų nėra.
                    </Alert>
                )}
            </div>
        </div>
    );
};

export default InvoicesListPage;
